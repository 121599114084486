import moment from "moment-timezone"
import {adminFunctions, db} from "../firebase/Firebase"
import {collection, doc, getDocs, setDoc, updateDoc} from "firebase/firestore"
import {useEffect, useState} from "react"

const padLeadingZeros = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

const Inzendingen = ({inzendingen, deviceWidth, rondes, uid}) => {

    const [geblokkeerdeSpelers, setGeblokkeerdeSpelers] = useState([])
    const [spelersZonderGoedAntwoord, setSpelersZonderGoedAntwoord] = useState([])

    useEffect(() => {
        const getData = async () => {
            let blocked = []
            let nocorrect = []
            let snap = await getDocs(collection(db, 'spelers'))
            for (let d of snap.docs) {
                if (d.data().BLOCKED) blocked.push(d.data().speler)
                if (!d.data().CORRECT_COUNT || d.data().CORRECT_COUNT === 0) nocorrect.push(d.data().speler)
            }
            setGeblokkeerdeSpelers(blocked)
            setSpelersZonderGoedAntwoord(nocorrect)
        }
        getData()
    }, [])

    // Blokkeer een speler
    const blokkeer = async (speler) => {
        let zekerweten = window.confirm(`Blokkeer @${speler}?`)
        if (zekerweten) {
            await updateDoc(doc(db, 'spelers', speler), {BLOCKED:true})
            setGeblokkeerdeSpelers([speler,...geblokkeerdeSpelers])
        }
        return true
    }

// Keur een goedgekeurd antwoord af
    const afkeuren = async (inzending) => {
        let zekerweten = window.confirm(`Keur antwoord '${inzending.tekst}' van @${inzending.gebruiker} af?`)
        if (zekerweten) {
            await adminFunctions({
                context:'inzendingen',
                action:'delete',
                user:uid,
                content:{
                    dm_id:inzending.id
                }
            })
        }
        return true
    }

    // Keur een afgekeurd antwoord goed
    const goedkeuren = async (inzending) => {
        let zekerweten = window.confirm(`Keur antwoord '${inzending.tekst}' van @${inzending.gebruiker} goed?`)
        if (zekerweten) {
            let dm_ronde
            await rondes.forEach(ronde => {
                let rondestart = moment.tz(`${ronde.start} 20:15:00`, "Europe/Amsterdam").utc()
                if (parseInt(inzending.timestamp, 10) >= parseInt(rondestart.format("x"), 10) &&
                    parseInt(inzending.timestamp, 10) < parseInt(rondestart.add(1, "days").format("x"), 10)) {
                    return dm_ronde = ronde.id
                }
            })
            let dm_rondedata = rondes.find(o => o.id === dm_ronde)
            await updateDoc(doc(db, 'inzendingen', inzending.id), {
                beoordeling:3
            })
            await setDoc(doc(db, 'scores', inzending.id), {
                speler:inzending.gebruiker,
                timestamp:String(inzending.timestamp),
                ronde:parseInt(dm_ronde, 10),
                beoordeling:3
            })
            if (dm_rondedata.antwoord) {
                await adminFunctions({
                    context:'twitter',
                    action:'send_dm',
                    user:uid,
                    content:{
                        dm_tekst:`Je antwoord "${inzending.tekst}" is alsnog goedgekeurd, gefeliciteerd! (Het juiste antwoord was ${dm_rondedata.antwoord}.)`,
                        dm_gebruiker:inzending.gebruiker
                    }
                })

            } else {
                await adminFunctions({
                    context:'twitter',
                    action:'send_dm',
                    user:uid,
                    content:{
                        dm_tekst:`Je antwoord "${inzending.tekst}" is alsnog goedgekeurd, gefeliciteerd! (Het juiste antwoord was '${dm_rondedata.titel}' van ${dm_rondedata.artiest}.)`,
                        dm_gebruiker:inzending.gebruiker
                    }
                })

            }
        }
        return true
    }

    return <table>
        <tbody>
        {inzendingen && inzendingen.length > 0 && inzendingen.map((inzending) =>
            <tr key={inzending.id}>
                <td className="data"
                    style={{color:inzending.beoordeling === 3 ? "green" : "darkred"}}>
                    {inzending.beoordeling === 3 ?
                        <input className="knop" type="button"
                               value={deviceWidth < 640 ? ' ' : 'maak fout'}
                               style={{margin:0, backgroundColor:'green'}}
                               onClick={() => afkeuren(inzending)}/>
                        :
                        <input className="knop" type="button"
                               value={deviceWidth < 640 ? ' ' : 'maak goed'}
                               style={{margin:0, backgroundColor:`${geblokkeerdeSpelers.includes(inzending.gebruiker)?'dimgray':'darkred'}`}}
                               onClick={() => goedkeuren(inzending)} disabled={geblokkeerdeSpelers.includes(inzending.gebruiker)}/>
                    }
                </td>
                {deviceWidth > 640 &&
                    <td className="data">
                        {inzending.id}&nbsp;
                    </td>
                }
                <td className="data">
                    <b>{padLeadingZeros(inzending.ronde, 3)}</b>&nbsp;
                </td>
                <td className="data">
                    {moment.unix(Math.floor(parseInt(inzending.timestamp, 10) / 1000)).tz("Europe/Amsterdam").format(deviceWidth < 640 ? "HH:mm:ss" : "YYYY-MM-DD HH:mm:ss")}&nbsp;
                </td>
                <td className="data">
                    {spelersZonderGoedAntwoord.includes(inzending.gebruiker) &&
                        !geblokkeerdeSpelers.includes(inzending.gebruiker) &&
                        <>
                        @{inzending.gebruiker}&nbsp;
                        <i className="fa-solid fa-lock-open" onClick={() => blokkeer(inzending.gebruiker)}
                           style={{cursor:'pointer'}}/>
                            </>}
                    {spelersZonderGoedAntwoord.includes(inzending.gebruiker) &&
                        geblokkeerdeSpelers.includes(inzending.gebruiker) &&
                        <>
                            <span style={{textDecoration:'line-through'}}>@{inzending.gebruiker}</span>&nbsp;
                            <i className="fa-solid fa-lock"/>
                        </>}
                    {!spelersZonderGoedAntwoord.includes(inzending.gebruiker) &&
                        !geblokkeerdeSpelers.includes(inzending.gebruiker) &&
                        <>
                            @{inzending.gebruiker}&nbsp;
                        </>
                    }
                </td>
                <td className="data"
                    style={{color:inzending.beoordeling === 3 ? "green" : (inzending.beoordeling > 0 ? "darkgoldenrod" : "darkred")}}>
                    <b>{inzending.tekst}</b>
                </td>
            </tr>
        )}
        </tbody>
    </table>

}

export default Inzendingen