import {Fragment, useEffect, useState} from "react"
import {collection, getDocs} from "firebase/firestore"
import {db} from "../firebase/Firebase"

const Seizoenen = () => {
    const [rondes, setRondes] = useState([])
    const [seizoen, setSeizoen] = useState(0)
    const [alleSeizoenen, setAlleSeizoenen] = useState([])
    const [seizoenRondes, setSeizoenRondes] = useState([])

    const [finishedLoading, setFinishedLoading] = useState(false)

    const [totaalAntwoorden, setTotaalAntwoorden] = useState(0)
    const [eersteAntwoord, setEersteAntwoord] = useState(null)
    const [laatsteAntwoord, setLaatsteAntwoord] = useState(null)
    const [alleWinsten, setAlleWinsten] = useState(null)
    // const [langsteSerie, setLangsteSerie] = useState(null)
    // const [besteRonde, setBesteRonde] = useState(null)
    // const [slechtsteRonde, setSlechtsteRonde] = useState(null)
    const [snelsteAntwoord, setSnelsteAntwoord] = useState(null)

    // VERZAMEL ALLE CIJFERS
    useEffect(() => {
        const fetchData = async () => {
            setFinishedLoading(false)
            seizoenRondes.sort((a, b) => a.ronde - b.ronde)
            let ta=0
            let w=[]
            // let br=null
            // let sr=null
            let sa=null
            for (let r of seizoenRondes) {
                if (r.CORRECT_COUNT) ta+=r.CORRECT_COUNT
                if (r.CORRECT_FIRST) {
                    let wi = w.findIndex(o => o.speler === r.CORRECT_FIRST.gebruiker)
                    if (wi > -1) {
                        w[wi].count++
                    } else {
                        w.push({speler:r.CORRECT_FIRST.gebruiker, count:1})
                    }
                    if ((sa && r.CORRECT_FIRST.SPEED<sa.SPEED) || !sa) {
                        sa=r.CORRECT_FIRST
                    }
                }
            }
            setTotaalAntwoorden(ta)
            seizoenRondes[0].CORRECT_FIRST && setEersteAntwoord(seizoenRondes[0].CORRECT_FIRST)
            seizoenRondes[seizoenRondes.length-1].CORRECT_LAST && setLaatsteAntwoord(seizoenRondes[seizoenRondes.length-1].CORRECT_LAST)
            w.sort((a,b)=>b.count-a.count)
            setAlleWinsten(w)
            setSnelsteAntwoord(sa)
        }
        if (seizoen > 0) fetchData().then(() => setFinishedLoading(true))
    }, [seizoenRondes, seizoen])

    useEffect(() => {
        const fetchData = async () => {
            let toState = []
            let s = await getDocs(collection(db, 'rondes'))
            for (let d of s.docs) {
                toState.push(d.data())
            }
            setRondes(toState)
        }

        fetchData()
    }, [])

    useEffect(() => {
        let s = []
        if (seizoen > 0) s = rondes.filter(o => o.SEASON === seizoen)
        setSeizoenRondes(s)
    }, [seizoen, rondes])

    useEffect(() => {
        if (rondes && rondes.length > 0) {
            rondes.sort((a, b) => a.ronde - b.ronde)
            let toState = []
            for (let r of rondes) {
                if (r.SEASON) {
                    let i = toState.findIndex(o => o.SEASON === r.SEASON)
                    if (i > -1) {
                        toState[i].LAST = r.ronde
                    } else {
                        toState.push({SEASON:r.SEASON, FIRST:r.ronde, LAST:r.ronde})
                    }
                }
            }
            setAlleSeizoenen(toState)
        }
    }, [rondes])

    return (

        <Fragment>
            <select value={seizoen} onChange={(e) => setSeizoen(parseInt(e.target.value, 10))}>
                <option value={0}/>
                {alleSeizoenen && alleSeizoenen.length > 0 && alleSeizoenen.map(i =>
                    <option key={i.SEASON} value={i.SEASON}>{i.SEASON} (rondes {i.FIRST} t/m {i.LAST})</option>
                )}
            </select>
            {finishedLoading ?
                <p>
                    Er werden in seizoen {seizoen} in totaal {totaalAntwoorden} goede antwoorden gegeven.<br/>
                    Het eerste antwoord werd gegeven door @{eersteAntwoord && eersteAntwoord.gebruiker && eersteAntwoord.gebruiker} en
                    het seizoen werd afgesloten door @{laatsteAntwoord && laatsteAntwoord.gebruiker && laatsteAntwoord.gebruiker}.<br/>
                    De meeste overwinningen werden gescoord door @{alleWinsten.length>0 && alleWinsten[0].speler} met een totaal van {alleWinsten.length>0 && alleWinsten[0].count}.<br/>
                    Het snelste antwoord werd gegeven door @{snelsteAntwoord && snelsteAntwoord.gebruiker && snelsteAntwoord.gebruiker} in ronde {snelsteAntwoord && snelsteAntwoord.ronde && snelsteAntwoord.ronde} met een snelheid van {snelsteAntwoord && snelsteAntwoord.SPEED && snelsteAntwoord.SPEED/1000} seconden.<br/>

                </p>
                :
                <p>laden...</p>
            }
        </Fragment>
    )
}

export default Seizoenen