import {Link} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {collection, getDocs, query, where} from "firebase/firestore"
import {db} from "../firebase/Firebase"

const Knoppen = ({dezeRonde, huidigeRonde, deviceWidth, alert}) => {
    const [zoekterm, setZoekterm] = useState(null)
    const [zoekResultaten, setZoekResultaten] = useState([])
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        const zoeken = () => new Promise(async (resolve) => {
            let toState = []
            let clipsSnap = await getDocs(query(collection(db, "clips"), where("status", "==", 2)))
            clipsSnap.forEach(clip => {
                if (clip.data().artiest.toLowerCase().includes(zoekterm.toLowerCase()) || clip.data().titel.toLowerCase().includes(zoekterm.toLowerCase()) || String(clip.data().ronde) === zoekterm.toLowerCase()) {
                    if (clip.data().ronde < huidigeRonde) toState.push({
                        type:'ronde',
                        resultaat:clip.data().ronde % 100 === 0 ? `bonusronde` : `${clip.data().artiest} - ${clip.data().titel}`,
                        id:clip.data().ronde
                    })
                }
            })
            let spelerSnap = await getDocs(query(collection(db, "spelers")))
            spelerSnap.forEach(speler => {
                if (speler.id.toLowerCase().includes(zoekterm.toLowerCase()) || speler.data().speler.toLowerCase().includes(zoekterm.toLowerCase())) {
                    toState.push({
                        type:'speler',
                        resultaat:`@${speler.id}`,
                        id:speler.id
                    })
                }
            })
            return resolve(toState)
        })

        if (zoekterm && zoekterm !== '' && zoekterm.length >= 1) {
            zoeken()
                .then(s => s.sort((a, b) => a.id > b.id ? -1 : 1))
                .then(s => s.sort((a, b) => a.type > b.type ? 1 : -1))
                .then(s => setZoekResultaten(s))
        } else {
            setZoekResultaten([])
        }
    }, [zoekterm, huidigeRonde])

    return (huidigeRonde && dezeRonde &&
        deviceWidth > 640 ?
            <table className="navigatiepijlen">
                <tbody>
                <tr>
                    <td>
                        {alert && <i className="fa-solid fa-triangle-exclamation navigatiepijl navigatiepijlknipper"/>}
                        <Link to={'/meta'} title="Over de quiz en de maker"><i
                            className="fa-regular fa-circle-question navigatiepijl"/></Link>&nbsp;
                        <Link to={'/steun'} title="Steun de quiz!"><i
                            className="fa-regular fa-thumbs-up navigatiepijl"/></Link>&nbsp;
                        {dezeRonde > 10 || !dezeRonde ?
                            <i onClick={() => window.location.href = `/ronde/${dezeRonde - 10}`}
                               className="fas fa-angle-double-left navigatiepijl" title="10 rondes terug"/>
                            :
                            dezeRonde <= 10 && dezeRonde > 1 ?
                                <i onClick={() => window.location.href = `/ronde/1`}
                                   className="fas fa-angle-double-left navigatiepijl" title="10 rondes terug"/>
                                :
                                <i className="fas fa-angle-double-left navigatiepijldisabled"/>
                        }
                        {dezeRonde > 1 || (!dezeRonde && huidigeRonde > 1) ?
                            <i onClick={() => window.location.href = `/ronde/${dezeRonde - 1}`}
                               className="fas fa-angle-left navigatiepijl" title="vorige ronde"/>
                            :
                            <i className="fas fa-angle-left navigatiepijldisabled"/>
                        }
                        <i onClick={() => window.location.href = `/`}
                           className={`fas fa-home navigatiepijl`}
                           title="huidige ronde"/>
                        {huidigeRonde - dezeRonde > 1 && dezeRonde && dezeRonde !== huidigeRonde ?
                            <i onClick={() => window.location.href = `/ronde/${dezeRonde + 1}`}
                               className="fas fa-angle-right navigatiepijl" title="volgende ronde"/>
                            :
                            huidigeRonde - dezeRonde === 1 && dezeRonde && dezeRonde !== huidigeRonde ?
                                <i onClick={() => window.location.href = `/`}
                                   className="fas fa-angle-right navigatiepijl" title="volgende ronde"/>
                                :
                                <i className="fas fa-angle-right navigatiepijldisabled"/>

                        }
                        {dezeRonde && huidigeRonde - dezeRonde > 10 && dezeRonde !== huidigeRonde ?
                            <i onClick={() => window.location.href = `/ronde/${dezeRonde + 10}`}
                               className="fas fa-angle-double-right navigatiepijl" title="10 rondes vooruit"/>
                            :
                            dezeRonde && huidigeRonde - dezeRonde <= 10 && dezeRonde !== huidigeRonde ?
                                <i onClick={() => window.location.href = `/`}
                                   className="fas fa-angle-double-right navigatiepijl" title="10 rondes vooruit"/>
                                :
                                <i className="fas fa-angle-double-right navigatiepijldisabled"/>
                        }
                    </td>
                </tr>
                <tr>
                    <td style={{padding:'2px'}}>
                    <span className="navInput" style={{padding:'4px'}}><i className="fas fa-search"/>&nbsp;
                        <input type="text" className="navInput" style={{width:'8em'}}
                               onKeyUp={(e) => setZoekterm(String(e.target.value))}/>
                        {zoekResultaten && zoekResultaten.length > 0 && <div className="zoekresultaten">
                            {zoekResultaten.map(r => {
                                return (r.type === 'ronde' ?
                                        <p key={r.id}>ronde {r.id}: <a className="zoekresultaat"
                                                                       href={`/ronde/${r.id}`}>{r.resultaat}</a></p>
                                        :
                                        r.type === 'speler' && <p key={r.id}>speler: <a className="zoekresultaat"
                                                                                        href={`/speler/${r.id}`}>{r.resultaat}</a>
                                        </p>
                                )
                            })
                            }

                        </div>}
                    </span>
                    </td>
                </tr>
                </tbody>
            </table>
            :
            <>
                <table className="navigatiepijlen">
                    <tbody>
                    <tr>
                        <td>
                            {alert && <><i
                                className="fa-solid fa-triangle-exclamation navigatiepijl navigatiepijlknipper"/>&nbsp;</>}
                            <i className="fa-solid fa-bars" style={{fontSize:'2em', cursor:'pointer'}}
                               onClick={() => setShowMenu(!showMenu)}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding:'2px'}}>
                    <span className="navInput" style={{padding:'4px'}}><i className="fas fa-search"/>&nbsp;
                        <input type="text" className="navInput" style={{width:'8em'}}
                               onKeyUp={(e) => setZoekterm(String(e.target.value))}/>
                        {zoekResultaten && zoekResultaten.length > 0 && <div className="zoekresultaten">
                            {zoekResultaten.map(r => {
                                return (r.type === 'ronde' ?
                                        <p key={r.id}>ronde {r.id}: <a className="zoekresultaat"
                                                                       href={`/ronde/${r.id}`}>{r.resultaat}</a></p>
                                        :
                                        r.type === 'speler' && <p key={r.id}>speler: <a className="zoekresultaat"
                                                                                        href={`/speler/${r.id}`}>{r.resultaat}</a>
                                        </p>
                                )
                            })
                            }

                        </div>}
                    </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className={showMenu ? 'navmenu' : 'navmenuhidden'}>
                    <span onClick={() => window.location.href = `/`} style={{cursor:'pointer'}}>
                        <i className={`fas fa-home navigatiepijltje`} title="huidige ronde"/> huidige ronde
                    </span>
                    <hr/>
                    {dezeRonde > 1 || (!dezeRonde && huidigeRonde > 1) ?
                        <span onClick={() => window.location.href = `/ronde/${dezeRonde - 1}`}
                              style={{cursor:'pointer'}}>
                            <i className="fas fa-angle-left navigatiepijltje" title="vorige ronde"/>vorige ronde
                        </span>
                        :
                        <span className="navigatiepijltjedisabled">
                            <i className="fas fa-angle-left"/> vorige ronde
                        </span>
                    }
                    <br/>
                    {huidigeRonde - dezeRonde > 1 && dezeRonde && dezeRonde !== huidigeRonde ?
                        <span onClick={() => window.location.href = `/ronde/${dezeRonde + 1}`}
                              style={{cursor:'pointer'}}>
                            <i className="fas fa-angle-right navigatiepijltje" title="vorige ronde"/>volgende ronde
                        </span>
                        :
                        huidigeRonde - dezeRonde === 1 && dezeRonde && dezeRonde !== huidigeRonde ?
                            <span onClick={() => window.location.href = `/`} style={{cursor:'pointer'}}>
                                <i className="fas fa-angle-right navigatiepijltje" title="vorige ronde"/>volgende ronde
                        </span>
                            :
                            <span className="navigatiepijltjedisabled">
                                <i className="fas fa-angle-right"/> volgende ronde
                        </span>

                    }
                    <hr/>
                    <span
                        onClick={() => window.location.href = `/ronde/${Math.floor(Math.random() * (huidigeRonde)) + 1}`}
                        style={{cursor:'pointer'}}>
                                <i className="fas fa-shuffle navigatiepijltje" title="willekeurige ronde"/>willekeurige ronde
                        </span>
                    <hr/>
                    <span onClick={() => window.location.href = '/meta'} style={{cursor:'pointer'}}><i
                        className="fa-regular fa-circle-question navigatiepijltje"/> over de quiz</span><br/>
                    <span onClick={() => window.location.href = '/steun'} style={{cursor:'pointer'}}><i
                        className="fa-regular fa-thumbs-up navigatiepijltje"/> steun de quiz</span><br/>
                </div>
            </>
    )
}

export default Knoppen