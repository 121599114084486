const Meta = () => {
    return (
        <div className="content">
            <h2 style={{textAlign:'center'}}>
                Over de quiz en de maker
            </h2>
            <table>
                <tbody>
                <tr>
                    <td className="overTableLeft">
                        <i className="fa-solid fa-video"/>
                    </td>
                    <td className="overTableRight">
                        De <b>videoclipgifquiz</b> is een vervolg van de <a href="https://outroquiz.nl" target="_new">outroquiz</a>, die gedurende de jaren 2017 en 2018 je kennis van outro's testte. De geschikte outro's raakten op, dus na 730 rondes was het tijd om de quiz op te doeken.
                    </td>
                </tr>
                <tr>
                    <td className="overTableLeft">
                        <i className="fa-solid fa-computer"/>
                    </td>
                    <td className="overTableRight">
                        Deze quiz is een <b>hobbyproject</b> en volledig <i>from scratch</i> geschreven en geprogrammeerd. De frontend is geschreven in React, de backend in NodeJS en de hele toko draait op Firebase van Google.
                    </td>
                </tr>
                <tr>
                    <td className="overTableLeft">
                        <i className="fa-solid fa-face-smile"/>
                    </td>
                    <td className="overTableRight">
                        Ik ben <b>Wouter</b>, bouwjaar 1976. Ik woon in Groningen, heb drie zoons en ben treindienstleider. In mijn vrije tijd maak ik websites, luister ik veel muziek, loop ik hard en ben ik marshal op het TT-circuit van Assen.
                    </td>
                </tr>
                <tr>
                    <td className="overTableLeft">
                        <i className="fa-solid fa-sitemap"/>
                    </td>
                    <td className="overTableRight">
                        Andere <b>websites</b> die ik heb gebouwd:<br/>
                        <ul>
                            <li><a href="https://regenrace.nl" target="_new">regenrace</a>, een buienradar voor Formule 1, MotoGP en andere raceseries</li>
                            <li><a href="https://fullwet.com" target="_new">fullwet</a>, de Engelstalige versie van regenrace.nl</li>
                            <li><a href="https://blekejet.nl" target="_new">blekejet</a>, gewijd aan het iconische nummer <b>Achter Glas</b> van de Tröckener Kecks</li>
                            <li><a href="https://tis3.nl" target="_new">tis3</a>, een volledig overzicht van spoorwegongelukken in Nederland</li>
                            <li><a href="https://outroquiz.nl" target="_new">outroquiz</a>, een voormalige quiz met outro's</li>
                            <li><a href="https://penaltypoints.fullwet.com" target="_new">penaltypoints</a>, waarop ik de strafpunten van de Formule 1-coureurs bijhoud</li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}
export default Meta
