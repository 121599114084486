import {useEffect, useState, Fragment, useMemo} from "react"
import {doc, updateDoc} from "firebase/firestore"
import {adminFunctions, db} from "../firebase/Firebase"
import {ZoekInSpotify} from "./SpotifyClient"

const Clips = ({clips, showClip,uid}) => {
    const [editClip, setEditClip] = useState(null)
    const [sort, setSort] = useState('ronde')
    const [editClipData, setEditClipData] = useState(null)
    const [spotifyJaren, setSpotifyJaren] = useState([])
    const [discogsResults, setDiscogsResults] = useState([])

    const [regexArtistError, setRegexArtistError] = useState(false)
    const [regexTitleError, setRegexTitleError] = useState(false)

    const allAttribs = ['id', 'artiest', 'titel', 'jaar', 'ronde', 'status', 'x_artiest', 'x_titel']
    const editableAttribs = ['artiest', 'titel', 'jaar', 'x_artiest', 'x_titel']
    const numberAttribs = ['jaar']

    const allClips = useMemo(() => {
            const collator = new Intl.Collator('en', {numeric: true, sensitivity: 'base'})
            return clips.sort((a, b) => collator.compare(a[sort], b[sort]))
        }, [clips, sort]
    )

    // Sla wijzigingen in clip op
    const schrijfClip = async () => {
        if (editClip && editClipData) {
            await updateDoc(doc(db, 'clips', editClip), editClipData)
            setEditClipData(null)
            setEditClip(null)
            setSpotifyJaren([])
            setDiscogsResults([])
        }
        return true
    }

    const wijzigClipData = async (event) => {
        let {value} = event.target
        if (numberAttribs.includes(event.target.name)) value = parseInt(value, 10)
        setEditClipData({...editClipData, [event.target.name]: value})
        return true
    }

    const zoekSpotifyJaar = async () =>
        ZoekInSpotify(`${editClipData.artiest} ${editClipData.titel}`)
            .then(resultaat => {
                let toState = []
                resultaat.map(r => toState.push({jaar: r.album_jaar, titel: r.album_titel, artiest: r.album_artiest}))
                toState.sort((a, b) => a.jaar - b.jaar)
                setSpotifyJaren(toState)
                return true
            })

    const kiesSpotifyJaar = async (jaar) => {
        setEditClipData({...editClipData, jaar: jaar})
        document.getElementById(`jaar${editClip}`).style.backgroundColor = 'red'
        setTimeout(() => {
            document.getElementById(`jaar${editClip}`).style.backgroundColor = 'limegreen'
            return document.getElementById(`jaar${editClip}`).value = jaar
        }, 2000)
    }

    const zoekDiscogsJaar = async () => {
        let {data} = await adminFunctions({
            context: 'tracks',
            action: 'search',
            user: uid,
            content: {
                titel: editClipData.titel,
                artiest: editClipData.artiest
            }
        })
        data.sort((a,b)=>parseInt(a.year,10)-parseInt(b.year,10))
        setDiscogsResults(data)
    }


    useEffect(() => {
        if (allClips.find(o => o.REGEX_ARTIST_CORRECT === 0 || !o.REGEX_ARTIST_CORRECT)) {
            setRegexArtistError(true)
        } else {
            setRegexArtistError(false)
        }
        if (allClips.find(o => o.REGEX_TITLE_CORRECT === 0 || !o.REGEX_TITLE_CORRECT)) {
            setRegexTitleError(true)
        } else {
            setRegexTitleError(false)
        }
    }, [allClips])

    useEffect(() => {
        const newEdit = async () => {
            setEditClipData(null)
            if (editClip) {
                let data = await allClips.find(o => o.id === editClip)
                setEditClipData({...data})
            } else {
                setEditClipData(null)
            }
            setDiscogsResults([])
            return setSpotifyJaren([])
        }

        newEdit()
    }, [editClip, allClips])

    return <table id="clipstable">
        <thead>
        <tr>
            {
                allAttribs.map(
                    a => <td onClick={() => setSort(a)} key={a}
                             className={`
                ${sort === a ? 'sorted' : ''} 
                ${(a === 'x_artiest' && regexArtistError) || (a === 'x_titel' && regexTitleError) ? 'regex_incorrect' : undefined}
            `}>{a}</td>
                )
            }
            <td/>
            <td/>
        </tr>
        </thead>
        <tbody>
        {allClips && allClips.length > 0 && allClips.map(clip =>
            editClip === clip.id && editClipData ?
                <Fragment key={clip.id}>
                    <tr className="data">
                        <td><input id={`id${clip.id}`} name={`id`} key={`id${clip.id}`} type="text"
                                   defaultValue={editClipData.id}
                                   readOnly={!editableAttribs.includes('id')}
                                   className={!editableAttribs.includes('id') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`artiest${clip.id}`} name={`artiest`} key={`artiest${clip.id}`} type="text"
                                   defaultValue={editClipData.artiest}
                                   readOnly={!editableAttribs.includes('artiest')}
                                   className={!editableAttribs.includes('artiest') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`titel${clip.id}`} name={`titel`} key={`titel${clip.id}`} type="text"
                                   defaultValue={editClipData.titel}
                                   readOnly={!editableAttribs.includes('titel')}
                                   className={!editableAttribs.includes('titel') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`jaar${clip.id}`} name={`jaar`} key={`jaar${clip.id}`} type="text"
                                   defaultValue={editClipData.jaar}
                                   readOnly={!editableAttribs.includes('jaar')}
                                   className={!editableAttribs.includes('jaar') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`ronde${clip.id}`} name={`ronde`} key={`ronde${clip.id}`} type="text"
                                   defaultValue={editClipData.ronde}
                                   readOnly={!editableAttribs.includes('ronde')}
                                   className={!editableAttribs.includes('ronde') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`status${clip.id}`} name={`status`} key={`status${clip.id}`} type="text"
                                   defaultValue={editClipData.status}
                                   readOnly={!editableAttribs.includes('status')}
                                   className={!editableAttribs.includes('status') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`x_artiest${clip.id}`} name={`x_artiest`} key={`x_artiest${clip.id}`} type="text"
                                   defaultValue={editClipData.x_artiest}
                                   readOnly={!editableAttribs.includes('x_artiest')}
                                   className={!editableAttribs.includes('x_artiest') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input id={`x_titel${clip.id}`} name={`x_titel`} key={`x_titel${clip.id}`} type="text"
                                   defaultValue={editClipData.x_titel}
                                   readOnly={!editableAttribs.includes('x_titel')}
                                   className={!editableAttribs.includes('x_titel') ? 'readonly' : 'edit'}
                                   onChange={(event) => wijzigClipData(event)}/>
                        </td>
                        <td><input type="button" className="knop" value="save" onClick={() => schrijfClip()}/></td>
                    </tr>
                    <tr className="data spotify">
                        <td><input className="knop" type="button" value="zoek op discogs"
                                   onClick={() => zoekDiscogsJaar()}/></td>
                        <td colSpan={allAttribs.length}>
                            {discogsResults && discogsResults.length > 0 && discogsResults.map((s, i) =>
                                    s.year && <Fragment key={`spotify${i}`}>
                                        <input style={{width: 'auto'}} type="button" className="knop" value={s.year}
                                               onClick={() => kiesSpotifyJaar(parseInt(s.year, 10))}/>
                                        &nbsp;{s.title}<br/></Fragment>

                            )}
                        </td>
                    </tr>

                </Fragment>
                :
                <tr key={clip.id} className="data">
                    {allAttribs.map(a => <td className={
                        (a === 'x_artiest' && clip.REGEX_ARTIST_CORRECT !== 1) || (a === 'x_titel' && clip.REGEX_TITLE_CORRECT !== 1) ? 'regex_incorrect' : undefined
                    } key={a}>{clip[a]}</td>)}
                    <td><input type="button" className="knop" value="edit" onClick={() => setEditClip(clip.id)}/></td>
                    <td><input className="knop" style={{margin: '0 6px'}} type="button" value="&#128247;"
                               onClick={() => showClip(clip.id)}/>
                    </td>
                </tr>
        )
        }
        </tbody>
    </table>
}

export default Clips
