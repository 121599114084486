import {lazy, Suspense, useEffect, useState} from "react"

import './App.css'
import {Switch, Route, BrowserRouter} from "react-router-dom"
import "normalize.css"

import Loading from "./Loading"
import Navigatie from "./Navigatie/Navigatie"

import {db} from './firebase/Firebase'
import {doc, getDoc, onSnapshot} from "firebase/firestore"
import Admin from "./Admin/Admin"
import Statistieken from "./Statistieken"
import Speler from "./Speler"

import ReactGA from "react-ga"
import Steun from "./Steun"
import Meta from "./Meta"

if (!window.location.href.includes("localhost") && !window.location.href.includes("admin")) {
    const {measurementId} = require("./firebase/FirebaseConfig.json")
    ReactGA.initialize(measurementId)
}

const Opgave = lazy(() => import("./Opgave"))
const Inzendingen = lazy(() => import("./Inzendingen/Inzendingen"))
const Over = lazy(() => import("./Over"))

const App = () => {
    const [deviceWidth, setDeviceWidth] = useState(null)
    const [huidigeRonde, setHuidigeRonde] = useState(null)
    const [huidigeRondeTimestampStart,setHuidigeRondeTimestampStart]=useState(null)

    useEffect(() => {
        setDeviceWidth(
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        )
    }, [])

    useEffect(() => {
        window.addEventListener("resize", () =>
            setDeviceWidth(
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            )
        )
        return window.removeEventListener("resize", null)
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "tellers", "huidige_ronde"), async (d) => {
            setHuidigeRonde(d.data().id)
            let ronde = await getDoc(doc(db, "rondes", String(d.data().id)))
            let rts=parseInt(ronde.data().TIMESTAMP_START,10)
            setHuidigeRondeTimestampStart(rts)
        })

        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (!window.location.href.includes("localhost") && !window.location.href.includes("admin")) {
            ReactGA.pageview(window.location.pathname + window.location.search)
        }
    }, [])

    return (
        <div className="App">
            <BrowserRouter>
                <Navigatie huidigeRonde={huidigeRonde} deviceWidth={deviceWidth} huidigeRondeTimestamp={huidigeRondeTimestampStart}/>
                <Switch>
                    <Route path={'/admin'}>
                        <Suspense fallback={<Loading/>}>
                            <Admin deviceWidth={deviceWidth}/>
                        </Suspense>
                    </Route>
                    <Route path={'/speler'}>
                        <Suspense fallback={<Loading/>}>
                            <Speler deviceWidth={deviceWidth} huidigeRonde={huidigeRonde}/>
                        </Suspense>
                    </Route>
                    <Route path={'/steun'}>
                        <Suspense fallback={<Loading/>}>
                            <Steun/>
                        </Suspense>
                    </Route>
                    <Route path={'/meta'}>
                        <Suspense fallback={<Loading/>}>
                            <Meta/>
                        </Suspense>
                    </Route>
                    <Route>
                        <Suspense fallback={<Loading/>}>
                            <div className="gridContainer">
                                <Opgave huidigeRonde={huidigeRonde}/>
                                <Inzendingen huidigeRonde={huidigeRonde} deviceWidth={deviceWidth}/>
                                {deviceWidth > 1023 ? <><Over/><Statistieken huidigeRonde={huidigeRonde}/></> : <>
                                    <Statistieken huidigeRonde={huidigeRonde}/><Over/></>}
                            </div>
                        </Suspense>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App
