const Spotify = require('node-spotify-api')

const ZoekInSpotify = (q) =>
    new Promise(async (resolve, reject) => {
        let spotify_client = await new Spotify({
            id:"fb4c015922934e1996f24beb9529d2b5",
            secret:"bc36b137f16d461086653c7bf416985b"
        })
        q && spotify_client.search({
            type:'track',
            query:q,
            market:'NL'
        })
            .then(async (res) => {
                let toResolve = []
                let hits
                if (res && res.tracks && res.tracks.items && res.tracks.items.length > 0) {
                    hits = res.tracks.items
                    await hits.sort((a, b) => a.popularity < b.popularity ? 1 : -1)
                } else {
                    return resolve([])
                }
                hits.forEach(hit => {
                    let duration_m = Math.floor(hit.duration_ms / 60000)
                    toResolve.push({
                        album_titel:hit.album.name,
                        album_jaar:hit.album.release_date.substring(0, 4),
                        album_track_nr:hit.track_number,
                        duration_m:duration_m,
                        duration_s:Math.floor(hit.duration_ms / 1000) - (duration_m * 60)
                    })
                    return resolve(toResolve)
                })
            })
            .catch(e => {
                console.log(e)
                return reject(e)
            })

    })

export {ZoekInSpotify}