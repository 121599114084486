import {Fragment, useEffect, useState} from "react"
import moment from "moment-timezone"
import {addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where} from "firebase/firestore"
import {adminFunctions, db} from "../firebase/Firebase"

const padLeadingZeros = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

const Rondes = ({clips, rondes, files, showClip, uid, deviceWidth}) => {
    const [editRonde, setEditRonde] = useState(null)
    const [clipsvoorstel, setClipsvoorstel] = useState([])
    const [ongebruikteClips, setOngebruikteClips] = useState([])
    const [hint, setHint] = useState("")

    useEffect(() => {
        if (clips.length > 0) {
            let toState = []
            for (let c of clips) {
                if (c.status !== 1 && c.status !== 2) toState.push(c)
            }
            toState.sort((a, b) => a.artiest.localeCompare(b.artiest))
            setOngebruikteClips(toState)
        }
    }, [clips])

    const verwisselRondes = async (r1, r2) => {
        let c1 = rondes.find(o => o.ronde === r1).clip
        let c2 = rondes.find(o => o.ronde === r2).clip
        await updateDoc(doc(db, 'rondes', String(r1)), {
            clip:c2
        })
        await updateDoc(doc(db, 'rondes', String(r2)), {
            clip:c1
        })
        return true
    }

    const vervangRonde = async (r, clipNieuw, clipOud) => {
        await updateDoc(doc(db, 'rondes', String(r)), {
            clip:clipNieuw
        })
        await updateDoc(doc(db, 'clips', clipNieuw), {
            status:1
        })
        await updateDoc(doc(db, 'clips', clipOud), {
            status:0
        })
        setEditRonde(null)
        return true
    }

    const stelClipsVoor = (aantal = 7) => {
        if (clips && clips.length > 0 && files && files.length > 0) {
            let ongebruikt = []
            clips.forEach((c) => {
                if (c.status !== 1 && c.status !== 2 && c.x_artiest && c.x_titel && files.includes(c.id)) {
                    ongebruikt.push(c)
                }
            })
            let shuffledClips = ongebruikt
                .map((value) => ({value, sort:Math.random()}))
                .sort((a, b) => a.sort - b.sort)
                .map(({value}) => value)
            let toState = []
            let laatsteRondeDag = rondes[rondes.length - 1].start
            let laatsteRondeId = rondes[rondes.length - 1].ronde
            let i = 1

            shuffledClips.forEach(c => {
                if (i <= aantal) {
                    let nieuweStart = moment(laatsteRondeDag).add(i, 'days').format("YYYY-MM-DD")
                    toState.push({
                        start:nieuweStart,
                        nieuweronde:laatsteRondeId + i,
                        ...c
                    })
                }
                i++
            })
            setClipsvoorstel(toState)
            return true
        }
    }

    const schrijfRondes = async () => {
        if ((uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2") && clipsvoorstel && clipsvoorstel.length > 0) {

            for (const clip of clipsvoorstel) {
                let {nieuweronde, start, id} = clip
                await setDoc(doc(db, 'rondes', String(nieuweronde)), {
                    ronde:nieuweronde,
                    start:start,
                    clip:id
                })
                await updateDoc(doc(db, 'clips', id), {
                    status:1
                })
            }
            return setClipsvoorstel([])
        }
        return true
    }

    const editHint = (tekst) => {
        return setHint(tekst)
    }

    const geefHint = async (ronde, tweet_id, masto_id) => {
        if ((uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2") && hint && hint.length > 0) {
            let hints = await getDocs(query(collection(db, "hints"), where("ronde", "==", parseInt(ronde, 10))))
            let newOrder = hints.size
            await addDoc(collection(db, 'hints'), {
                ronde:parseInt(ronde, 10),
                order:newOrder,
                hint:hint
            })
            await adminFunctions({
                context:'mastodon',
                action:'send_status',
                user:uid,
                content:{
                    tekst:`${hint} #hint`,
                    masto_id:masto_id
                }
            })
            await adminFunctions({
                context:'twitter',
                action:'send_status',
                user:uid,
                content:{
                    tekst:`${hint} #hint`,
                    tweet_id:tweet_id
                }
            })
            setHint("")
            document.getElementById("hint").value = ""
        }
        return true
    }

    return <Fragment>
        {clips && clips.length > 0 && rondes && rondes.length > 0 && rondes.map((ronde, i) =>
            <table key={ronde.ronde}>
                <tbody>
                <tr className="data">
                    <td>
                        {padLeadingZeros(ronde.ronde, 3)}&nbsp;
                        {ronde.start}<br/>
                        <input className="knop" style={{margin:'0 6px'}} type="button"
                               onClick={() => verwisselRondes(ronde.ronde, ronde.ronde - 1)} value="&#5169;"
                               disabled={i < 3}/>
                        <input className="knop" style={{margin:'0 6px'}} type="button"
                               onClick={() => verwisselRondes(ronde.ronde, ronde.ronde + 1)} value="&#5167;"
                               disabled={i + 1 === rondes.length || i < 2}/>
                        {deviceWidth < 640 && <br/>}
                        <input className="knop" style={{margin:'0 6px'}} type="button" value="&#9998;" disabled={i < 2}
                               onClick={() => {
                                   editRonde === ronde.ronde ? setEditRonde(null) : setEditRonde(ronde.ronde)
                               }}/>
                        <input className="knop" style={{margin:'0 6px'}} type="button" value="&#128247;"
                               onClick={() => showClip(ronde.clip)}/>
                    </td>
                    <td>
                        {ronde.artiest} - {ronde.titel} {ronde.jaar && <Fragment>({ronde.jaar})</Fragment>}
                    </td>
                </tr>
                {i === 1 && <tr>
                    <td colSpan={2}><input type="text" id="hint" size={30} onChange={(e) => editHint(e.target.value)}
                                           defaultValue={hint}/> <input className="knop" type="button" value="geef hint"
                                                                        onClick={() => geefHint(ronde.ronde, ronde.tweet_id,ronde.masto_id)}/>
                    </td>
                </tr>}
                {editRonde === ronde.ronde &&
                    <tr>
                        <td>&nbsp;</td>
                        <td>
                            <select id="clip" className="knop">
                                <option></option>
                                {ongebruikteClips.map(c =>
                                    <option key={c.id} value={c.id}>{c.artiest} - {c.titel}</option>
                                )}
                            </select>
                            <input className="knop" style={{margin:'0 6px'}} type="button"
                                   onClick={() => vervangRonde(ronde.ronde, document.getElementById("clip").value, ronde.clip)}
                                   value="opslaan"/>

                        </td>
                    </tr>
                }
                </tbody>
            </table>
        )}

        <input className="knop" type="button" value="+1" onClick={() => stelClipsVoor(1)}/>
        <input className="knop" type="button" value="+7" onClick={() => stelClipsVoor(7)}/>
        <input className="knop" type="button" value="+14" onClick={() => stelClipsVoor(14)}/>
        {clipsvoorstel && clipsvoorstel.length > 0 && clipsvoorstel.map((clip) =>
            <p className="data" key={clip.id}>
                {padLeadingZeros(clip.nieuweronde, 3)}:&nbsp;
                {clip.start}&nbsp;|&nbsp;
                {clip.artiest} - {clip.titel} {clip.jaar && <Fragment>({clip.jaar})</Fragment>}
                {files.includes(clip.id) && <>&nbsp;&nbsp;<input className="knop" style={{margin:'0 6px'}}
                                                                 type="button" value="&#128247;"
                                                                 onClick={() => showClip(clip.id)}/>
                </>}
            </p>
        )}
        {clipsvoorstel && clipsvoorstel.length > 0 &&
            <p><input className="knop" type="button" value="opslaan"
                      onClick={() => schrijfRondes()}/></p>
        }
    </Fragment>

}

export default Rondes