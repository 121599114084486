import './Admin.css'
import {adminFunctions, auth, db, storage} from "../firebase/Firebase"

import {Fragment} from "react"
import {
    collection,
    onSnapshot,
    query,
    doc,
    updateDoc,
    where,
    orderBy,
    limit
} from "firebase/firestore"
import {ref, listAll, getMetadata} from "firebase/storage"

import {onAuthStateChanged} from "firebase/auth"
import NewClip from "./NewClip"
import Rondes from "./Rondes"
import Foto from "./Foto"
import Inzendingen from "./Inzendingen"
import Clips from "./Clips"
import Seizoenen from "./Seizoenen"

const {useState, useEffect} = require("react")


const Admin = ({deviceWidth}) => {
    const [uid, setUid] = useState(null)
    const [showRegexCheck, setShowRegexCheck] = useState(false)
    const [showDuplicates, setShowDuplicates] = useState(false)
    const [showInzendingen, setShowInzendingen] = useState(false)
    const [showSeizoenen, setShowSeizoenen] = useState(false)
    const [showRounds, setShowRounds] = useState(false)
    const [showClips, setShowClips] = useState(false)
    const [showFiles, setShowFiles] = useState(false)
    const [showNewClip, setShowNewClip] = useState(false)

    const [clips, setClips] = useState([])
    const [rondes, setRondes] = useState([])
    const [files, setFiles] = useState([])
    const [inzendingen, setInzendingen] = useState([])

    const [editClip, setEditClip] = useState(null)
    const [editClipData, setEditClipData] = useState([])
    const [showClip, setShowClip] = useState(null)
    const [huidigeRonde, setHuidigeRonde] = useState(null)

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUid(user.uid)
        } else {
            setUid(null)
        }
    })

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "tellers", "huidige_ronde"), (doc) => setHuidigeRonde(doc.data().id))

        return () => unsubscribe()
    }, [])

    // Laas alle bestanden uit storage
    useEffect(() => {
        const getFiles = async () => {
            let toState = []
            await listAll(ref(storage, 'clips'))
                .then(result =>
                    result.items.forEach(i =>
                        getMetadata(i)
                            .then(m => toState.push(m.name.slice(0, -4)))))
                .then(() => {
                    setFiles(toState)
                })
        }

        if (rondes.length > 0 && clips.length > 0) getFiles()
    }, [rondes, clips])

// Laad alle clips
    useEffect(() => {
        if (uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2" || uid==="M8l2a4mmjVfStyuWFo7OTJ6rgm13") {
            try {
                const q = query(collection(db, "clips"))
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const nieuweClips = []
                        querySnapshot.forEach(async doc => nieuweClips.push({
                            id:doc.id,
                            regex:checkRegex(doc.data()), ...doc.data()
                        }))
                        nieuweClips.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                        setClips(nieuweClips)
                    }
                )
                return () => unsubscribe()
            } catch (e) {
                console.error(e)
            }
        }
    }, [uid])

// Laad alle rondes
    useEffect(() => {
        if ((uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2" || uid==="M8l2a4mmjVfStyuWFo7OTJ6rgm13") && clips && clips.length > 0 && huidigeRonde) {
            const q = query(collection(db, "rondes"), where('ronde', '>', huidigeRonde - 2), orderBy('ronde', 'desc'))
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const nieuweRondes = []
                    querySnapshot.forEach(doc => {
                        let data = doc.data()
                        let rondeclip = clips.find(o => o.id === data.clip) || false
                        nieuweRondes.push({
                            id:doc.id,
                            titel:rondeclip.titel || null,
                            artiest:rondeclip.artiest || null,
                            jaar:rondeclip.jaar || null,
                            ...data
                        })
                    })
                    nieuweRondes.sort((a, b) => (a.ronde > b.ronde) ? 1 : -1)
                    setRondes(nieuweRondes)
                }
            )
            return () => unsubscribe()
        }
    }, [uid, clips, huidigeRonde])

// Laad alle inzendingen
    useEffect(() => {
        if (uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2" || uid==="M8l2a4mmjVfStyuWFo7OTJ6rgm13") {
            try {
                const q = query(collection(db, "inzendingen"), orderBy("timestamp", "desc"), limit(100))
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const nieuweInzendingen = []
                        querySnapshot.forEach(doc => {
                            nieuweInzendingen.push({id:doc.id, ...doc.data()})
                        })
                        setInzendingen(nieuweInzendingen)
                    }
                )
                return () => unsubscribe()
            } catch (e) {
                console.error(e)
            }
        }
    }, [uid])

// Controleer de regexes van een clip
    const checkRegex = ({artiest, titel, x_artiest, x_titel}) =>
        !!(x_artiest &&
            x_titel &&
            new RegExp(x_artiest, "ig").test(artiest) && new RegExp(x_titel, "ig").test(titel))

// Controleer op dubbele clips
    const zoekDoublures = (i, a, t) => {
        let doublure = false
        clips.forEach((clip) => {
            if (clip.id === i) return

            if (
                clip.x_artiest && new RegExp(clip.x_artiest, "ig").test(a) &&
                clip.x_titel && new RegExp(clip.x_titel, "ig").test(t)
            ) {
                doublure = true
            }
        })
        return doublure
    }

    const startEditClipData = (clip) => {
        setEditClipData(clips.find(o => o.id === clip))
        setEditClip(clip)
    }

    const changeClipData = async (event) => {
        event.preventDefault()
        const {value} = event.target
        setEditClipData({...editClipData, [event.target.name]:value})
        return testEditClipData()
    }

    const testEditClipData = () => {
        if (!new RegExp(document.getElementById("edit_x_artiest").value, "ig").test(document.getElementById("edit_artiest").value)) {
            console.log('x_artiest failed!')
            document.getElementById("edit_x_artiest").style.backgroundColor = "red"
        } else {
            document.getElementById("edit_x_artiest").style.backgroundColor = null
        }
        if (!new RegExp(document.getElementById("edit_x_titel").value, "ig").test(document.getElementById("edit_titel").value)) {
            console.log('x_titel failed!')
            document.getElementById("edit_x_titel").style.backgroundColor = "red"
        } else {
            document.getElementById("edit_x_titel").style.backgroundColor = null
        }
    }

    // Sla wijzigingen in clip op
    const schrijfClip = async () => {
        if (editClip && editClipData) {
            await updateDoc(doc(db, 'clips', editClip), editClipData)
            setEditClipData([])
        }
        return true
    }

    const forceCycle = async (mode) => {
        let zekerweten = window.confirm(`run_cycle uitvoeren?`)
        if (zekerweten) {
            await adminFunctions({
                context:'cycle',
                action:'run',
                content:{
                    mode:mode
                },
                user:uid
            })
        }
        return true
    }

    return (
        <>
            <div style={{width:'100%'}}>
                {(uid === "UtQuqZsAsdXfKVXm5E7PUTRd7ly1" || uid === "Uw7fhFgjqkXqZfZWxYIQbUgvIzG2" || uid === "iJYoPUxk5ZUmfHxTRuY48VxjgaO2" || uid==="M8l2a4mmjVfStyuWFo7OTJ6rgm13") &&
                    <>
                        <h2 onClick={() => setShowNewClip(!showNewClip)}>Clip toevoegen</h2>
                        <div className={showNewClip ? 'show' : 'hide'}>
                            <NewClip clips={clips}/>
                        </div>

                        <h2 onClick={() => setShowRegexCheck(!showRegexCheck)}>Check regex</h2>
                        <div className={showRegexCheck ? 'show' : 'hide'}>
                            {clips && clips.length > 0 && clips.map((clip) =>
                                    checkRegex(clip) || <Fragment key={clip.id}><p className="data"
                                                                                   onClick={() => editClip ? setEditClip(null) : startEditClipData(clip.id)}>
                                        {clip.artiest} - {clip.titel}
                                    </p>
                                        {clip.id === editClip &&
                                            <table className="data" style={{width:'auto'}}>
                                                <tbody>
                                                <tr className="blauw">
                                                    <td>
                                                        artiest
                                                    </td>
                                                    <td>
                                                        titel
                                                    </td>
                                                    <td>
                                                        regex artiest
                                                    </td>
                                                    <td>
                                                        regex titel
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input value={editClipData.artiest} name="artiest" id="edit_artiest"
                                                               onChange={(event) => changeClipData(event)}/>
                                                    </td>
                                                    <td>
                                                        <input value={clip.titel} name="titel" id="edit_titel"
                                                               onChange={(event) => changeClipData(event)}/>
                                                    </td>
                                                    <td>
                                                        <input value={editClipData.x_artiest} name="x_artiest"
                                                               id="edit_x_artiest"
                                                               onChange={(event) => changeClipData(event)}/>
                                                    </td>
                                                    <td>
                                                        <input value={clip.x_titel} name="x_titel" id="edit_x_titel"
                                                               onChange={(event) => changeClipData(event)}/>
                                                    </td>
                                                    <td>
                                                        <input type="button" className="knop" value="opslaan"
                                                               onClick={() => schrijfClip()}/>
                                                    </td>
                                                </tr>
                                                <tr style={{fontSize:'0.8em'}}>
                                                    <td>
                                                        \d&nbsp;&nbsp;1 teken<br/>
                                                        \w&nbsp;&nbsp;1 woord<br/>
                                                        \s&nbsp;&nbsp;1 whitespace teken
                                                    </td>
                                                    <td>
                                                        +&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 of meer<br/>
                                                        &#123;3&#125;&nbsp;&nbsp;&nbsp;exact 3<br/>
                                                        &#123;2,4&#125;&nbsp;2 t/m 4<br/>
                                                        &#123;3,&#125; &nbsp;3 of meer<br/>
                                                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 of meer<br/>
                                                        ?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 of 1
                                                    </td>
                                                    <td>
                                                        .&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elk teken (punt)<br/>
                                                        [abc]&nbsp;&nbsp;een van de tekens<br/>
                                                        -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;range (t/m)<br/>
                                                        [^a]&nbsp;&nbsp;&nbsp;niet a<br/>
                                                        [^a-z]&nbsp;niet a t/m z<br/>
                                                        |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR
                                                    </td>
                                                    <td>
                                                        ^&nbsp;&nbsp;&nbsp;&nbsp;start van string<br/>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(dakje)<br/>
                                                        $&nbsp;&nbsp;&nbsp;&nbsp;einde van string
                                                    </td>

                                                </tr>
                                                </tbody>
                                            </table>
                                        }
                                    </Fragment>
                            )}
                            ({clips.length} clips gecheckt)
                        </div>

                        <h2 onClick={() => setShowFiles(!showFiles)}>Check files</h2>
                        <div className={showFiles ? 'show' : 'hide'}>
                            {clips && clips.length > 0 && clips.map((clip) =>
                                !files.includes(clip.id) && <p
                                    key={clip.id}>{clip.id}: {clip.artiest} - {clip.titel}</p>)
                            }
                            ({clips.length} clips gecheckt)
                        </div>

                        <h2 onClick={() => setShowDuplicates(!showDuplicates)}>Check doublures</h2>
                        <div className={showDuplicates ? 'show' : 'hide'}>
                            {clips && clips.length > 0 && clips.map((clip) =>
                                zoekDoublures(clip.id, clip.artiest, clip.titel) && <p
                                    key={clip.id}>{clip.id}: {clip.artiest} - {clip.titel}</p>
                            )
                            }
                            ({clips.length} clips gecheckt)
                        </div>

                        <h2 onClick={() => setShowClips(!showClips)}>Clips</h2>
                        <div className={showClips ? 'show' : 'hide'}>
                            <Clips clips={clips} showClip={(clip) => setShowClip(clip)} uid={uid}/>
                        </div>

                        <h2 onClick={() => setShowRounds(!showRounds)}>Rondes</h2>
                        <div className={showRounds ? 'show' : 'hide'}>
                            <Rondes clips={clips} rondes={rondes} files={files} showClip={(clip) => setShowClip(clip)}
                                    uid={uid} deviceWidth={deviceWidth}/>
                        </div>

                        <h2 onClick={() => setShowInzendingen(!showInzendingen)}>Inzendingen</h2>
                        <div className={showInzendingen ? 'show' : 'hide'}>
                            <Inzendingen inzendingen={inzendingen} deviceWidth={deviceWidth} rondes={rondes} uid={uid}/>
                        </div>
                        <h2 onClick={() => setShowSeizoenen(!showSeizoenen)}>Seizoenen</h2>
                        {showSeizoenen &&
                            <div>
                                <Seizoenen/>
                            </div>
                        }
                        <input className="knop" type="button" value="cycle 02"
                               onClick={() => forceCycle(2)}/>
                        rondes | spelers speeds; corrects; mediums; wins; bonus; taart | inzendings speeds | clips | globals<br/>
                        <input className="knop" type="button" value="cycle 03"
                               onClick={() => forceCycle(3)}/>
                        spelers fast/slow; series<br/>
                        <input className="knop" type="button" value="cycle 19"
                               onClick={() => forceCycle(19)}/>
                        clips / global<br/>
                        <input className="knop" type="button" value="cycle 20"
                               onClick={() => forceCycle(20)}/>
                        spelers speeds; series; years; bonus | globals artiesten; years<br/>
                        <input className="knop" type="button" value="cycle 21"
                               onClick={() => forceCycle(21)}/>
                        spelers wins<br/>
                        <input className="knop" type="button" value="cycle even"
                               onClick={() => forceCycle(8)}/>
                        auth users to firestore users | legacy spelers to users<br/>
                        <input className="knop" type="button" value="cycle odd"
                               onClick={() => forceCycle(9)}/>
                        clean run
                    </>
                }
            </div>
            {showClip && <Foto clip={showClip} onHide={() => setShowClip(null)}/>}
        </>
    )

}


export default Admin
