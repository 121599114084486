import React, {useEffect, useState} from 'react'
import {onAuthStateChanged, signInWithPopup, signOut} from "firebase/auth"
import {auth, twitterProvider} from "../firebase/Firebase"

import {getDownloadURL, ref} from "firebase/storage"
import {storage} from "../firebase/Firebase"
import {useRouteMatch} from "react-router-dom"

import Knoppen from "./Knoppen"

const Navigatie = ({huidigeRonde, deviceWidth,huidigeRondeTimestamp}) => {
    const [headerUrl, setHeaderUrl] = useState(null)
    const [dezeRonde, setDezeRonde] = useState(null)
    const [uid, setUid] = useState(null)
    const [displayname, setDisplayname] = useState(null)
    const [userImage,setUserImage]=useState(null)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        setInterval(() => {
            let nu = Date.now()
            if (nu - huidigeRondeTimestamp < 60000 && nu - huidigeRondeTimestamp >0) {
                setAlert(true)
            } else {
                setAlert(false)
            }
        }, 1000)
    }, [huidigeRondeTimestamp])

    const login = (provider) => {
        switch (provider) {
            case "twitter":
                signInWithPopup(auth, twitterProvider)
                    .then((result) => {
                        const user = result.user
                        setUid(user.uid)
                        setDisplayname(user.displayName)
                        user.photoURL && setUserImage(user.photoURL)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                break
            default:
        }
        return true
    }

    const uitloggen = async () => {
        await signOut(auth)
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUid(user.uid)
            setDisplayname(user.displayName)
            user.photoURL && setUserImage(user.photoURL)
        } else {
            setUid(null)
            setDisplayname(null)
            setUserImage(null)
        }
    })


    let rondeMatch = useRouteMatch({
        path:'/ronde/:rondenummer',
        strict:true,
        sensitive:true
    })

    useEffect(() => {
        if (rondeMatch) {
            setDezeRonde(parseInt(rondeMatch.params.rondenummer, 10))
        } else {
            setDezeRonde(parseInt(huidigeRonde, 10))
        }
    }, [rondeMatch, huidigeRonde])

    useEffect(() => {
        const fetchHeader = async () =>
            setHeaderUrl(await getDownloadURL(ref(storage, `assets/logo_line_yellow_trans.png`)))

        return fetchHeader()
    }, [])


    return <div className="navigatie" id="top">
        <span className="titel"><img src={headerUrl || ''} className="titellogo" alt="ZVGQ"
                                     title={`versie ${require("../../package.json").version}`}/></span><br/>
        <span className="twitterblok"><i className="fab fa-twitter"/>&nbsp;
            {uid ? <span onClick={() => uitloggen()}>{displayname}</span>
                :
                <span onClick={() => login("twitter")}>log in</span>}
        </span>
        <Knoppen dezeRonde={dezeRonde} huidigeRonde={huidigeRonde} deviceWidth={deviceWidth} alert={alert}/>
    </div>
}

export default Navigatie