import {useRouteMatch} from "react-router-dom"
import React, {useEffect, useState, Fragment} from "react"
import {doc, getDoc} from "firebase/firestore"
import {db} from "./firebase/Firebase"
import {Rondelink} from "./Links"

import moment from "moment"

const Speler = ({deviceWidth,huidigeRonde}) => {
    const [datumFormaat, setDatumFormaat] = useState("dddd DD MMMM YYYY HH:mm.ss")

    const [spelerId, setSpelerId] = useState(null)

    const [attemptFirst, setAttemptFirst] = useState(null)
    const [correctFirst, setCorrectFirst] = useState(null)
    const [correctLast, setCorrectLast] = useState(null)
    const [correctCount, setCorrectCount] = useState(null)
    const [winList, setWinList] = useState([])
    const [bonusList, setBonusList] = useState([])
    const [fastFive, setFastFive] = useState([])
    const [slowFive, setSlowFive] = useState([])
    const [seriesList, setSeriesList] = useState([])
    const [bronCount, setBronCount] = useState([])
    const [yearsList, setYearsList] = useState([])
    const [decadeList, setDecadeList] = useState([])
    const [donateur, setDonateur] = useState(false)

    const padLeadingZeroes = (num, size, str = '0') => {
        let s = num + ""
        while (s.length < size) s = str + s
        return s
    }

    const speedFormat = (speed) => {
        let hours = Math.floor(speed / 1000 / 60 / 60)
        let minutes = Math.floor((speed - (hours * 60 * 60 * 1000)) / 1000 / 60)
        let seconds = Math.floor((speed - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / 1000)
        let thousands = speed - (Math.floor(speed / 1000) * 1000)
        return {h:hours, m:minutes, s:seconds, t:thousands}
    }

    const toggle = (elementId, c) => {
        let ele = document.getElementById(elementId)
        if (ele.style.display === c) {
            ele.style.display = "none"
        } else {
            ele.style.display = c
        }
    }

    let spelerMatch = useRouteMatch({
        path:'/speler/:spelerid',
        strict:true,
        sensitive:true
    })

    useEffect(() => {
        if (deviceWidth < 640) {
            setDatumFormaat("dd DD.MM.YY HH:mm.ss")
        } else {
            setDatumFormaat("dddd DD MMMM YYYY HH:mm.ss")
        }
    }, [deviceWidth])

    useEffect(() => {
        if (spelerMatch && spelerMatch.params.spelerid) {
            setSpelerId(spelerMatch.params.spelerid)
        }
    }, [spelerMatch])

    useEffect(() => {
            const fetchData = async () => {
                let speler = await getDoc(doc(db, "spelers", spelerId))
                if (speler.exists()) {
                    let spelerData = speler.data()
                    setAttemptFirst(spelerData.ATTEMPT_FIRST)
                    setCorrectFirst(spelerData.CORRECT_FIRST)
                    setCorrectLast(spelerData.CORRECT_LAST)
                    setCorrectCount(spelerData.CORRECT_COUNT)
                    setWinList(spelerData.WIN_LIST)
                    setBonusList(spelerData.BONUS_LIST)
                    setFastFive(spelerData.FAST_FIVE)
                    setSlowFive(spelerData.SLOW_FIVE)
                    setSeriesList(spelerData.SERIES_LIST)
                    setBronCount(spelerData.BRON_COUNT)
                    setYearsList(spelerData.YEARS_LIST)
                    let decade_list = []
                    for (let y of spelerData.YEARS_LIST) {
                        if (y<1900) continue
                        let decade = Math.floor(y.year / 10) * 10
                        let i = decade_list.findIndex(o => o.decade === decade)
                        if (i > -1) {
                            decade_list[i].count+=y.count
                        } else {
                            decade_list.push({decade:decade, count:y.count})
                        }
                    }
                    decade_list.sort((a, b) => a.count === b.count ? a.decade - b.decade : b.count - a.count)
                    setDecadeList(decade_list)
                    spelerData.donateur && setDonateur(true)
                }
                return true
            }
            if (spelerId) fetchData()

        }, [spelerId]
    )

    return <div className="content">
        {spelerId &&
            <>
                <h2>@{spelerId}</h2>
                {donateur && <h4><i className="fa-regular fa-thumbs-up"/> vriendelijke donateur</h4>}
                <table className={`scoretabel statstabel ${deviceWidth < 640 ? 'scoretabelklein' : ''}`}>
                    <tbody>
                    {attemptFirst && attemptFirst.timestamp &&
                        <tr key="attemptFirst">
                            <td style={{textAlign:'right', width:'40%'}}>Eerste antwoordpoging:&nbsp;</td>
                            <td style={{
                                textAlign:'left',
                                width:'60%'
                            }}>&nbsp;{moment.unix(Math.floor(attemptFirst.timestamp / 1000)).format(datumFormaat)} (<Rondelink
                                text="ronde" ronde={attemptFirst.ronde}/>)
                            </td>
                        </tr>
                    }
                    {correctFirst && correctFirst.timestamp &&
                        <tr key="correctFirst">
                            <td style={{textAlign:'right', width:'40%'}}>Eerste juiste antwoord:&nbsp;</td>
                            <td style={{
                                textAlign:'left',
                                width:'60%'
                            }}>&nbsp;{moment.unix(Math.floor(correctFirst.timestamp / 1000)).format(datumFormaat)} (<Rondelink
                                text="ronde" ronde={correctFirst.ronde}/>)
                            </td>
                        </tr>
                    }
                    {correctLast && correctLast.timestamp &&
                        <tr key="correctLast">
                            <td style={{textAlign:'right', width:'40%'}}>Meest recente juiste antwoord:&nbsp;</td>
                            <td style={{
                                textAlign:'left',
                                width:'60%'
                            }}>&nbsp;{moment.unix(Math.floor(correctLast.timestamp / 1000)).format(datumFormaat)} (<Rondelink
                                text="ronde" ronde={correctLast.ronde}/>)
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {correctCount &&
                        <tr key="correctCount">
                            <td style={{textAlign:'right', width:'40%'}}>Aantal juiste antwoorden:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%'}}>&nbsp;{correctCount}</td>
                        </tr>
                    }
                    <tr key="winList">
                        <td style={{textAlign:'right', width:'40%'}}>Aantal overwinningen*:&nbsp;</td>
                        <td style={{
                            textAlign:'left',
                            width:'60%'
                        }}>&nbsp;{(winList && winList.length) || '0'} {winList && winList.length > 0 &&
                            <i className="statsUitklapLink" onClick={() => toggle("winListOverview", "table-row")}>(klik
                                hier
                                voor een overzicht)</i>}</td>
                    </tr>
                    {winList && winList.length > 0 &&
                        <tr key="winListOverview" id="winListOverview" style={{display:"none", width:'100%'}}>
                            <td colSpan={2}>
                                <table className={`scoretabel ${deviceWidth < 640 ? 'scoretabelklein' : ''}`}>
                                    <tbody>
                                    {winList.map(i => <tr style={{width:'100%'}} key={i.timestamp}>

                                            <td style={{textAlign:'right', width:'40%', fontSize:'0.9em'}}>
                                                <Fragment>{
                                                    moment.unix(Math.floor(i.timestamp / 1000)).format("dd DD MMM YYYY")
                                                }&nbsp;<br/></Fragment>
                                            </td>
                                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                                <Fragment>&nbsp;<Rondelink text="ronde" ronde={i.ronde}
                                                                           inhoud={i.ronde<huidigeRonde}/><br/></Fragment>

                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr key="bonusList">
                        <td style={{textAlign:'right', width:'40%'}}>Aantal bonuspunten:&nbsp;</td>
                        <td style={{
                            textAlign:'left',
                            width:'60%'
                        }}>&nbsp;{bonusList && bonusList.length > 0 ? bonusList.length : '0'} {bonusList && bonusList.length > 0 &&
                            <i className="statsUitklapLink"
                               onClick={() => toggle("bonusListOverview", "table-row")}>(klik hier
                                voor een overzicht)</i>}</td>
                    </tr>
                    {bonusList && bonusList.length > 0 &&
                        <tr key="bonusListOverview" id="bonusListOverview" style={{display:"none", width:'100%'}}>
                            <td colSpan={2}>
                                <table className={`scoretabel ${deviceWidth < 640 ? 'scoretabelklein' : ''}`}>
                                    <tbody>
                                    {bonusList.map(i => <tr style={{width:'100%'}} key={i.timestamp}>

                                            <td style={{textAlign:'right', width:'40%', fontSize:'0.9em'}}>
                                                <Fragment>{
                                                    moment.unix(Math.floor(i.timestamp / 1000)).format("dd DD MMM YYYY")
                                                }&nbsp;<br/></Fragment>
                                            </td>
                                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                                <Fragment>&nbsp;<Rondelink text="ronde" ronde={i.ronde}
                                                                           inhoud={i.ronde<huidigeRonde}/><br/></Fragment>

                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {fastFive && fastFive.length > 0 &&
                        <tr key="fastFive">
                            <td style={{textAlign:'right', width:'40%'}}>Snelste antwoorden:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                {fastFive.map(i =>
                                    <Fragment key={i.timestamp}>&nbsp;
                                        {speedFormat(i.speed).h > 0 && padLeadingZeroes(speedFormat(i.speed).h, 2) + 'u '}
                                        {(speedFormat(i.speed).h > 0 || speedFormat(i.speed).m > 0) && padLeadingZeroes(speedFormat(i.speed).m, 2) + 'm '}
                                        {padLeadingZeroes(speedFormat(i.speed).s, 2)}.
                                        <span
                                            style={{fontSize:'0.8em'}}>{padLeadingZeroes(speedFormat(i.speed).t, 3)}</span> sec
                                        : <Rondelink text="ronde" ronde={i.ronde} inhoud={i.ronde<huidigeRonde}/><br/>
                                    </Fragment>
                                )}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {slowFive && slowFive.length > 0 &&
                        <tr key="slowFive">
                            <td style={{textAlign:'right', width:'40%'}}>Traagste antwoorden:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                {slowFive.map(i =>
                                    <Fragment key={i.timestamp}>&nbsp;
                                        {speedFormat(i.speed).h > 0 && padLeadingZeroes(speedFormat(i.speed).h, 2) + 'u '}
                                        {(speedFormat(i.speed).h > 0 || speedFormat(i.speed).m > 0) && padLeadingZeroes(speedFormat(i.speed).m, 2) + 'm '}
                                        {padLeadingZeroes(speedFormat(i.speed).s, 2) + 's'}
                                        : <Rondelink text="ronde" ronde={i.ronde} inhoud={i.ronde<huidigeRonde}/><br/>
                                    </Fragment>
                                )}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr key="seriesList">
                        <td style={{textAlign:'right', width:'40%'}}>Series opeenvolgende antwoorden*:&nbsp;</td>
                        <td style={{
                            textAlign:'left',
                            width:'60%'
                        }}>&nbsp;{seriesList && seriesList.length > 0 ?
                            <i className="statsUitklapLink"
                               onClick={() => toggle("seriesListOverview", "inline-block")}>(klik hier
                                voor een overzicht)</i>
                            : <i>geen series van twee of meer antwoorden genoteerd</i>
                        }
                            {seriesList && seriesList.length > 0 &&
                                <div key="seriesListOverview" id="seriesListOverview"
                                     style={{display:"none", width:'100%'}}>
                                    {seriesList.map(i => <Fragment
                                        key={i.SERIES[0].timestamp}>&nbsp;{i['LENGTH']}x: <Rondelink text={'ronde'}
                                                                                                     ronde={i.SERIES[0].ronde}/> t/m <Rondelink
                                        ronde={i.SERIES[i.LENGTH - 1].ronde}/><br/></Fragment>
                                    )}
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {bronCount && bronCount.length > 0 &&
                        <tr key="bronCount">
                            <td style={{textAlign:'right', width:'40%'}}>Antwoordmethodes:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                {bronCount.map(i =>
                                    <Fragment key={i.bron}>&nbsp;
                                        {i.bron==='direct_message' && 'DM'}
                                        {i.bron==='website' && 'Website'}
                                        : {i.count}x ({Math.round(i.count/correctCount*1000)/10}%)
                                        <br/>
                                    </Fragment>
                                )}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {yearsList && yearsList.length > 0 &&
                        <tr key="yearsList">
                            <td style={{textAlign:'right', width:'40%'}}>Meeste juiste antwoorden per jaartal**:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                {yearsList.map((i,n) => {
                                        return i.year>0 &&  n < 10 && <Fragment key={i.year}>&nbsp;
                                            {i.year}: {i.count}x ({Math.round(i.count / correctCount * 1000) / 10}%)
                                            <br/>
                                        </Fragment>
                                    }
                                )}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    {decadeList && decadeList.length > 0 &&
                        <tr key="yearsList">
                            <td style={{textAlign:'right', width:'40%'}}>Meeste juiste antwoorden per decennium**:&nbsp;</td>
                            <td style={{textAlign:'left', width:'60%', fontSize:'0.9em'}}>
                                {decadeList.map(i=> {
                                        return i.decade>0 && <Fragment key={`d${i.decade}`}>&nbsp;
                                            {i.decade}-{i.decade + 9}: {i.count}x
                                            ({Math.round(i.count / correctCount * 1000) / 10}%)
                                            <br/>
                                        </Fragment>
                                    }
                                )}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
                <hr/>
                * gegevens worden niet direct verwerkt, maar enkel 's nachts tussen vier uur en half vijf<br/>
                ** gegevens worden niet direct verwerkt, maar enkel 's nachts tussen vier uur en half vijf en 's avonds tussen half negen en negen uur
            </>
        }
    </div>
}

export default Speler