import React, {useEffect, useState} from 'react'
import {getDownloadURL, ref} from "firebase/storage"
import {storage} from "./firebase/Firebase"

const Steun = () => {
    const [payPalImage, setPayPalImage] = useState(null)

    useEffect(() => {
        const fetchHeader = async () =>
            setPayPalImage(await getDownloadURL(ref(storage, `assets/donate-with-paypal-transparent.png`)))

        fetchHeader()
    }, [])

    return <div className="content">
        <h2 style={{textAlign:'center'}}>
            Steun de videoclipgifquiz
        </h2>
        <table>
            <tbody>
            <tr>
                <td className="overTableLeft">
                    <i className="fa-regular fa-circle-question"/>
                </td>
                <td className="overTableRight">
                    Vind je de videoclipgifquiz <b>leuk</b>? Overweeg dan een financiële ondersteuning.
                </td>
            </tr>
            <tr>
                <td className="overTableLeft"/>
                <td className="overTableRight">
                    <a href="https://www.paypal.com/donate/?business=356C68SSMN7VG&no_recurring=0&currency_code=EUR"
                       target="_new">
                        <img src={payPalImage} width={250} alt="Doneer met PayPal"/>
                    </a><br/>
                    <b>Vermeld wel je gebruikersnaam</b>, zodat ik je een icoontje kan geven.<br/>
                </td>
            </tr>
            <tr>
                <td className="overTableLeft">
                    <i className="fa-solid fa-sack-dollar"/>
                </td>
                <td className="overTableRight">
                    Deze quiz is een <b>hobbyproject</b>. Hoewel ik hem heb gemaakt uit liefde voor de muziek en het
                    plezier
                    van
                    het quizzen, is het draaien van deze quiz niet zonder kosten.<br/>
                    Hosting van de site kost geld, het draaien van de achterliggende processen (zoals het
                    antwoordapparaat)
                    ook.<br/>
                    We praten niet over honderden euro's, maar de quiz groeit, het aantal opgeslagen gegevens groeit
                    en
                    daarmee lopen de maandelijkse kosten inmiddels ook al in de tientallen euro's. Geen ramp, maar
                    het is
                    wel geld.
                </td>
            </tr>
            <tr>
                <td className="overTableLeft">
                    <i className="fa-regular fa-thumbs-up"/>
                </td>
                <td className="overTableRight">
                    Daarom ben je van harte welkom om een kleine financiële bijdrage te doen om
                    je <b>waardering</b> uit te
                    spreken over de quiz.<br/>
                    Dat kun je doen via bovenstaande PayPal-knop. Hiervoor heb je wel een PayPal-account nodig. Dat
                    is
                    gratis en kan zonder creditcard.<br/>
                    <b>Vermeld wel even je gebruikersnaam</b>, zodat ik je een icoontje kan geven.<br/>
                    Andere betaalmethodes of abonnementsvormen ben ik nog aan het onderzoeken, maar voor de meeste
                    opties
                    heb je een zakelijke bankrekening nodig en een KvK-inschrijving, wat een beetje overdreven is
                    voor een
                    hobbyproject.
                </td>
            </tr>
            <tr>
                <td className="overTableLeft">
                    <i className="fa-solid fa-handshake-angle"/>
                </td>
                <td className="overTableRight">
                    Een bijdrage is vrijwillig en <b>de quiz is en blijft gratis</b>!<br/>
                    Je krijgt geen voordelen of voorrang bij het raden van de clips.<br/>
                    Wel krijg je, als bedankje, een <b>speciaal icoontje</b> achter je naam. Als je dat niet wilt, laat
                    het me dan even weten.
                </td>
            </tr>
            </tbody>
        </table>
    </div>

}

export default Steun

