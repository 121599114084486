import {useEffect, useState, Fragment} from "react"
import {db, storage} from '../firebase/Firebase'
import {addDoc, collection} from 'firebase/firestore'
import {ref, uploadBytesResumable} from "firebase/storage"

const NewClip = ({clips}) => {
    const [newArtiest, setNewArtiest] = useState('')
    const [artiestDubbels, setArtiestDubbels] = useState(0)
    const [newTitel, setNewTitel] = useState('')
    const [titelDubbels, setTitelDubbels] = useState(0)
    const [newYoutube, setNewYoutube] = useState('')
    const [youtubeDubbels, setYoutubeDubbels] = useState(0)
    const [newId, setNewId] = useState(null)

    const changeClipData = async (event) => {
        event.preventDefault()
        setNewArtiest(document.getElementById("new_artiest").value)
        setNewTitel(document.getElementById("new_titel").value)
        setNewYoutube(document.getElementById("new_youtube").value.split('https://www.youtube.com/watch?v=')[1] || document.getElementById("new_youtube").value)

        return true
    }

    const saveToFirestore = async () => {
        let docRef = await addDoc(collection(db, "clips"), {
            artiest: newArtiest,
            titel: newTitel,
            youtube: newYoutube,
            status: 0
        })
        setNewId(docRef.id)
        setNewArtiest('')
        setNewTitel('')
        setNewYoutube('')
        return true
    }

    const saveToStorage = () => {
        let file = document.getElementById("bestand").files[0]
        console.log(file)
        let storageRef = ref(storage, `clips/${newId}.gif`)
        let task = uploadBytesResumable(storageRef, file)
        task.on('state_changed', (snapshot) => {
                document.getElementById("voortgang").value = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            },
            (error) => console.error(error),
            () => {
                window.location.reload()
            })
    }

    useEffect(() => {
        const testNewClipData = () => {
            let artiestCounter = 0
            let titelCounter = 0
            let youtubeCounter = 0
            for (let clip of clips) {
                if (clip.x_artiest && newArtiest.length > 0 && new RegExp(clip.x_artiest, "ig").test(newArtiest)) artiestCounter++
                if (clip.x_titel && newTitel.length > 0 && new RegExp(clip.x_titel, "ig").test(newTitel)) titelCounter++
                if (clip.youtube && newYoutube.length > 0 && clip.youtube === newYoutube) youtubeCounter++
            }
            setArtiestDubbels(artiestCounter)
            setTitelDubbels(titelCounter)
            setYoutubeDubbels(youtubeCounter)

            return true
        }

        testNewClipData()
    }, [newArtiest, newTitel, newYoutube, clips])

    return <Fragment>
        <p><input type="tekst" id="new_artiest" value={newArtiest} onChange={(event) => changeClipData(event)}/> artiest
            ({artiestDubbels})</p>
        <p><input type="tekst" id="new_titel" value={newTitel} onChange={(event) => changeClipData(event)}/> titel
            ({titelDubbels})</p>
        <p><input type="tekst" id="new_youtube" value={newYoutube}
                  onChange={(event) => changeClipData(event)}/> youtubelink ({youtubeDubbels})</p>
        <p><input type="button" value="opslaan" onClick={() => saveToFirestore()}
                  disabled={newArtiest.length === 0 || newTitel.length === 0 || newYoutube.length === 0}/></p>
        {newId && <p>{newId}</p>}
        <hr/>
        <p><input type="file" id="bestand" disabled={!newId} onChange={() => saveToStorage()}/></p>
        <p>
            <progress id="voortgang" value="0" max="100">0%</progress>
        </p>
    </Fragment>
}

export default NewClip
