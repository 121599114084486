// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import './index.css';
import App from './App';

import moment from "moment";
import "moment/locale/nl";

moment.locale("nl");

console.log('Koekoek!')

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
