import React, {Fragment, useEffect, useState} from 'react'
import {collection, query,  onSnapshot,  doc, getDocs, where} from "firebase/firestore"
import {db} from "./firebase/Firebase"
import {Rondelink, Spelerlink} from "./Links"
import moment from "moment"

const Statistieken = () => {
    const [inzendingenAantal, setInzendingenAantal] = useState([])
    const [inzendingenSpeed, setInzendingenSpeed] = useState([])
    const [inzendingenValreep, setInzendingenValreep] = useState([])
    const [winnaarsAantal, setWinnaarsAantal] = useState([])
    const [inzendingenSeries, setInzendingenSeries] = useState([])
    const [rondesMeeste, setRondesMeeste] = useState([])
    const [rondesMinste, setRondesMinste] = useState([])
    const [inzendingenTotaal, setInzendingenTotaal] = useState(null)
    const [gebruikersTotaal, setGebruikersTotaal] = useState(null)
    const [winnaarsTotaal, setWinnaarsTotaal] = useState(null)
    const [gemiddeldRonde, setGemiddeldRonde] = useState(null)
    const [gemiddeldSpeler, setGemiddeldSpeler] = useState(null)
    const [slowStarters, setSlowStarters] = useState([])
    const [huidigeRonde, setHuidigeRonde] = useState(null)
    const [artiestenCorrects, setArtiestenCorrects] = useState([])
    const [jarenCorrects, setJarenCorrects] = useState([])
    const [decenniaCorrects, setDecenniaCorrects] = useState([])
    const [clipsTotaal, setClipsTotaal] = useState(null)
    const [clipsPlanned, setClipsPlanned] = useState(null)
    const [clipsUnplanned, setClipsUnplanned] = useState(null)

    const padLeadingZeroes = (num, size) => {
        let s = num + ""
        while (s.length < size) s = '0' + s
        return s
    }

    function toggleCollapse(elementId) {
        let ele = document.getElementById(elementId)
        if (ele.style.display === "block") {
            ele.style.display = "none"
        } else {
            ele.style.display = "block"
        }
    }

    // HUIDIGE RONDE
    useEffect(() => {
        const q = doc(db, "tellers", "huidige_ronde")
        const unsub_updatetime = onSnapshot(q, (doc) => {
            let data = doc.data()
            data && data.id && setHuidigeRonde(parseInt(doc.data().id, 10))
        })
        return () => {
            unsub_updatetime()
        }
    })

    useEffect(() => {
        let q = doc(db, "GLOBAL", "STATS")
        const unsub = onSnapshot(q, doc => {
            let data = doc.data()
            setInzendingenTotaal(data.CORRECT_COUNT || null)
            setGemiddeldRonde(data.CORRECT_AVG_RONDE || null)
            setGemiddeldSpeler(data.CORRECT_AVG_SPELER || null)
            setGebruikersTotaal(data.SPELER_COUNT || null)
            setWinnaarsTotaal(data.WINNERS_COUNT || null)
            setClipsTotaal(data.CLIP_COUNT || null)
            setClipsPlanned(data.CLIP_PLANNED || null)
            setClipsUnplanned(data.CLIP_UNPLANNED || null)
        })

        return () => {
            unsub()
        }
    })

    useEffect(() => {
        let q = query(collection(db, "rondes"))
        const unsub = onSnapshot(q, (r) => {
            let alle_rondes = []

            for (let d of r.docs) {
                if (d.data().ronde <= huidigeRonde)
                    alle_rondes.push({VALREEP:d.data().TIMESTAMP_END - d.data().CORRECT_LAST.timestamp, ...d.data()})
            }

            // MEESTE INZENDINGEN
            alle_rondes.sort((a, b) => a.CORRECT_COUNT === b.CORRECT_COUNT ? a.ronde - b.ronde : b.CORRECT_COUNT - a.CORRECT_COUNT)
            let lastCount = 0
            let counter = 0
            let toState = []
            alle_rondes.forEach(i => {
                if (counter > 9 && i.CORRECT_COUNT !== lastCount) {
                    return true
                }
                toState.push({ronde:i.ronde, count:i.CORRECT_COUNT})
                lastCount = i.CORRECT_COUNT
                counter++
            })
            setRondesMeeste(toState)

            // MINSTE INZENDINGEN
            alle_rondes.sort((a, b) => a.CORRECT_COUNT === b.CORRECT_COUNT ? a.ronde - b.ronde : a.CORRECT_COUNT - b.CORRECT_COUNT)
            lastCount = 0
            counter = 0
            toState = []
            alle_rondes.forEach(i => {
                if (counter > 9 && i.CORRECT_COUNT !== lastCount) {
                    return true
                }
                toState.push({ronde:i.ronde, count:i.CORRECT_COUNT})
                lastCount = i.CORRECT_COUNT
                counter++
            })
            setRondesMinste(toState)

            // TRAAGSTE WINSTEN
            alle_rondes.sort((a, b) => b.SPEED_FIRST === a.SPEED_FIRST ? a.ronde - b.ronde : b.SPEED_FIRST - a.SPEED_FIRST)
            lastCount = 0
            counter = 0
            toState = []
            alle_rondes.forEach(i => {
                if (counter > 9 && i.SPEED_FIRST !== lastCount) {
                    return true
                }
                toState.push({speed:i.SPEED_FIRST, ...i.CORRECT_FIRST})
                lastCount = i.SPEED_FIRST
                counter++
            })
            setSlowStarters(toState)

            // LAATSTE INZENDINGEN
            alle_rondes.sort((a, b) => b.VALREEP === a.VALREEP ? a.ronde - b.ronde : a.VALREEP - b.VALREEP)
            lastCount = 0
            counter = 0
            toState = []
            alle_rondes.forEach(i => {
                if (counter > 11 && i.VALREEP !== lastCount) {
                    return true
                }
                if (!(i.VALREEP === 1 && i.ronde === 122) && !(i.VALREEP === 1 && i.ronde === 127)) toState.push({
                    speed:i.VALREEP,
                    ronde:i.ronde, ...i.CORRECT_LAST
                })
                lastCount = i.VALREEP
                counter++
            })
            setInzendingenValreep(toState)

        })

        return () => {
            unsub()
        }
    }, [huidigeRonde])

    useEffect(() => {
        let q = query(collection(db, "spelers"))
        const unsub = onSnapshot(q, (s) => {
            let alle_spelers = []
            let alle_series=[]

            for (let d of s.docs) {
                alle_spelers.push({...d.data(), WIN_COUNT:d.data().WIN_COUNT || 0})
                if (d.data().SERIES_LIST) {
                    for (let r of d.data().SERIES_LIST) {
                        alle_series.push(r)
                    }
                }
            }

            // AANTAL INZENDINGEN
            alle_spelers.sort((a, b) => b.CORRECT_COUNT - a.CORRECT_COUNT)
            let lastCount = 0
            let counter = 0
            let toState = []
            alle_spelers.forEach(i => {
                if (counter > 9 && i.CORRECT_COUNT !== lastCount) {
                    return true
                }
                toState.push({gebruiker:i.speler, count:i.CORRECT_COUNT})
                lastCount = i.CORRECT_COUNT
                counter++
            })
            setInzendingenAantal(toState)

            // AANTAL WINSTEN
            alle_spelers.sort((a, b) => b.WIN_COUNT - a.WIN_COUNT)
            lastCount = 0
            counter = 0
            toState = []
            alle_spelers.forEach(i => {
                if (counter > 9 && i.WIN_COUNT !== lastCount) {
                    return true
                }
                toState.push({gebruiker:i.speler, count:i.WIN_COUNT,CORRECT_COUNT:i.CORRECT_COUNT})
                lastCount = i.WIN_COUNT
                counter++
            })
            setWinnaarsAantal(toState)

            // LANGSTE SERIES
            alle_series.sort((a,b)=>a.LENGTH===b.LENGTH?a.SERIES[0].ronde-b.SERIES[0].ronde:b.LENGTH-a.LENGTH)
            let lastLength = 0
            counter = 0
            toState = []
            alle_series.forEach(i => {
                if (counter > 9 && i.LENGTH !== lastLength) {
                    return true
                }
                toState.push(i)
                lastLength = i.LENGTH
                counter++
            })
            setInzendingenSeries(toState)
        })

        return () => {
            unsub()
        }
    }, [])

    // SNELSTE INZENDINGEN
    useEffect(() => {
        let q = query(collection(db, "inzendingen"), where('SPEED', '<', 8000))

        const unsub = onSnapshot(q, (s) => {
            let snelste_corrects = []
            for (let c of s.docs) {
                if (c.data().beoordeling === 3) snelste_corrects.push(c.data())
            }

            // SNELSTE INZENDINGEN
            snelste_corrects.sort((a, b) => a.SPEED === b.SPEED ? a.ronde - b.ronde : a.SPEED - b.SPEED)

            let lastSpeed = 0
            let counter = 0
            let toState = []
            snelste_corrects.forEach(i => {
                if (counter > 9 && i.SPEED !== lastSpeed) {
                    return true
                }
                toState.push(i)
                lastSpeed = i.SPEED
                counter++
            })
            setInzendingenSpeed(toState)
        })


        return () => {
            unsub()
        }
    }, [])


    // ARTIESTEN CORRECT/COUNT NIEUW
    useEffect(() => {
        const getData = async () => {
            const q = query(collection(db, 'GLOBAL', 'STATS', 'ARTIESTEN'))
            const querySnapshot = await getDocs(q)
            const toState = []
            for (let doc of querySnapshot.docs) {
                let rondes = doc.data().rondes
                rondes.sort((a, b) => a - b)
                toState.push({...doc.data(), rondes:rondes})
            }
            toState.sort((a, b) =>
                a.corrects === b.corrects ? a.count === b.count ? a.artiest.toLowerCase().localeCompare(b.artiest.toLowerCase()) : b.count - a.count : b.corrects - a.corrects)
            let lastCorrects = 0
            let counter = 0
            let toState2 = []
            toState.forEach(i => {
                if (counter > 9 && i.corrects !== lastCorrects) {
                    return true
                }
                toState2.push(i)
                lastCorrects = i.corrects
                counter++
            })
            setArtiestenCorrects(toState2)
        }
        getData()
    }, [])

    // JAREN CORRECT/COUNT NIEUW
    useEffect(() => {
        const getData = async () => {
            const q = query(collection(db, 'GLOBAL', 'STATS', 'JAREN'))
            const querySnapshot = await getDocs(q)
            const toState = []
            for (let doc of querySnapshot.docs) {
                toState.push(doc.data())
            }
            toState.sort((a, b) =>
                a.corrects === b.corrects ? a.count === b.count ? a.jaar - b.jaar : b.count - a.count : b.corrects - a.corrects)
            let lastCorrects = 0
            let counter = 0
            let toState2 = []
            toState.forEach(i => {
                // i.rondes && i.rondes.sort((a, b) => a - b)
                if (counter > 9 && i.corrects !== lastCorrects) {
                    return true
                }
                toState2.push(i)
                lastCorrects = i.corrects
                counter++
            })
            setJarenCorrects(toState2)
            let toState3 = []
            toState.forEach(d => {
                let decade = Math.floor(d.jaar / 10) * 10
                let i = toState3.findIndex(o => o.decennium === decade)
                if (i > -1) {
                    toState3[i].count = toState3[i].count + d.count
                    toState3[i].corrects = toState3[i].corrects + d.corrects
                } else {
                    toState3.push({decennium:decade, count:d.count, corrects:d.corrects})
                }
            })
            toState3.sort((a, b) =>
                a.corrects === b.corrects ? a.count === b.count ? a.decennium - b.decennium : b.count - a.count : b.corrects - a.corrects
            )
            lastCorrects = 0
            counter = 0
            let toState4 = []
            toState3.forEach(i => {
                if (counter > 9 && i.corrects !== lastCorrects) {
                    return true
                }
                toState4.push(i)
                lastCorrects = i.corrects
                counter++
            })
            setDecenniaCorrects(toState4)

        }
        getData()
    }, [])

    return <div className="content">
        <h3>Statistieken</h3>
        <table className="scoretabel">
            <tbody>
            <tr key="spelerstotaal">
                <td style={{textAlign:'right', width:'50%'}}>Totaal aantal spelers&nbsp;</td>
                <td style={{textAlign:'left', width:'50%'}}>&nbsp;{gebruikersTotaal || null}</td>
            </tr>
            <tr key="winnaarstotaal">
                <td style={{textAlign:'right', width:'50%'}}>Aantal verschillende winnaars&nbsp;</td>
                <td style={{textAlign:'left', width:'50%'}}>&nbsp;{winnaarsTotaal || null}</td>
            </tr>
            <tr key="scorestotaal">
                <td style={{textAlign:'right', width:'50%'}}>Totaal aantal juiste antwoorden&nbsp;</td>
                <td style={{textAlign:'left', width:'50%'}}>&nbsp;{inzendingenTotaal || null}</td>
            </tr>
            <tr key="scoresspelersavg">
                <td style={{textAlign:'right', width:'50%'}}>Gemiddeld aantal antwoorden per speler&nbsp;</td>
                <td style={{
                    textAlign:'left',
                    width:'50%'
                }}>&nbsp;{gemiddeldSpeler}</td>
            </tr>
            <tr key="scoresrondesavg">
                <td style={{textAlign:'right', width:'50%'}}>Gemiddeld aantal antwoorden per ronde&nbsp;</td>
                <td style={{
                    textAlign:'left',
                    width:'50%'
                }}>&nbsp;{gemiddeldRonde}</td>
            </tr>
            <tr key="clips_totaal">
                <td style={{textAlign:'right', width:'50%'}}>Totaal aantal clipfragmenten&nbsp;</td>
                <td style={{
                    textAlign:'left',
                    width:'50%'
                }}>&nbsp;{clipsTotaal}</td>
            </tr>
            <tr key="clips_gepland">
                <td style={{textAlign:'right', width:'50%'}}><i>waarvan ingepland</i>&nbsp;</td>
                <td style={{
                    textAlign:'left',
                    width:'50%'
                }}>&nbsp;{clipsPlanned}</td>
            </tr>
            <tr key="clips_ongebruikt">
                <td style={{textAlign:'right', width:'50%'}}><i>waarvan nog niet ingepland</i>&nbsp;</td>
                <td style={{
                    textAlign:'left',
                    width:'50%'
                }}>&nbsp;{clipsUnplanned}</td>
            </tr>
            </tbody>
        </table>
        <hr className="scorelijn"/>
        {winnaarsAantal.length > 0 && <><h4 className="statsKop"
                                            onClick={() => toggleCollapse("winnaarsAantal")}>Spelers
            met de meeste winsten <i className="fas fa-trophy"/></h4>
            <div id="winnaarsAantal" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>aantal winsten</td>
                        <td>percentage van<br/>alle antwoorden<br/>van deze speler</td>
                    </tr>
                    </thead>
                    <tbody>
                    {winnaarsAantal.map((inzending) =>
                        <tr key={`${inzending.gebruiker} ${inzending.count}`}>
                            <td><Spelerlink speler={inzending.gebruiker} prijzen={false}/></td>
                            <td>{inzending.count}x</td>
                            <td>{Math.floor(inzending.count*100/inzending.CORRECT_COUNT)}%</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }
        {inzendingenAantal.length > 0 && <><h4 className="statsKop"
                                               onClick={() => toggleCollapse("scoresAantal")}>Spelers met de meeste
            juiste
            antwoorden <i
                className="far fa-check-square"/></h4>
            <div id="scoresAantal" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>aantal scores</td>
                        <td>percentage van<br/>alle rondes</td>
                    </tr>
                    </thead>
                    <tbody>
                    {inzendingenAantal.map((inzending) =>
                        <tr key={`${inzending.gebruiker} ${inzending.count}`}>
                            <td><Spelerlink speler={inzending.gebruiker} prijzen={false}/></td>
                            <td>{inzending.count}x</td>
                            <td>{Math.floor(inzending.count*100/huidigeRonde)}%</td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }
        {inzendingenSpeed.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("scoresSpeed")}>Snelste
            juiste antwoorden <i className="far fa-clock"/></h4>
            <div id="scoresSpeed" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>ronde</td>
                        <td>snelheid</td>
                    </tr>
                    </thead>
                    <tbody>
                    {inzendingenSpeed.map((inzending) =>
                        <tr key={`${inzending.gebruiker} ${inzending.ronde}`}>
                            <td><Spelerlink speler={inzending.gebruiker} prijzen={false}/></td>
                            <td><Rondelink ronde={inzending.ronde}/></td>
                            <td>{Math.floor(inzending.SPEED / 1000)}<span
                                style={{fontSize:'0.6em'}}>.{padLeadingZeroes(inzending.SPEED - (Math.floor(inzending.SPEED / 1000) * 1000), 3)}</span> seconden
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }
        {slowStarters.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("slowStarters")}>Slow
            starters<br/>
            <span className="oranje" style={{fontSize:'0.8em'}}>(langzaamste overwinningen)</span>
        </h4>
            <div id="slowStarters" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>ronde</td>
                        <td>tijd sinds start van ronde</td>
                    </tr>
                    </thead>
                    <tbody>
                    {slowStarters.map((inzending) =>
                        <tr key={`${inzending.gebruiker} ${inzending.ronde}`}>
                            <td><Spelerlink speler={inzending.gebruiker} prijzen={false}/></td>
                            <td><Rondelink ronde={inzending.ronde}/></td>
                            <td>
                                <>{moment.utc(inzending.speed).format('H:mm.ss')}</>
                                <span
                                    style={{fontSize:'0.6em'}}>.{padLeadingZeroes(inzending.speed - (Math.floor(inzending.speed / 1000) * 1000), 3)}</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }

        {inzendingenValreep.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("scoresValreep")}>
            Op de valreep
        </h4>
            <div id="scoresValreep" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>ronde</td>
                        <td>tijd tot sluiten ronde</td>
                    </tr>
                    </thead>
                    <tbody>
                    {inzendingenValreep.map((inzending) =>
                        <tr key={`${inzending.gebruiker} ${inzending.ronde}`}>
                            <td><Spelerlink speler={inzending.gebruiker} prijzen={false}/></td>
                            <td><Rondelink ronde={inzending.ronde}/></td>
                            <td>{Math.floor(inzending.speed / 1000)}<span
                                style={{fontSize:'0.6em'}}>.{padLeadingZeroes(inzending.speed - (Math.floor((inzending.speed) / 1000) * 1000), 3)}</span> seconden
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }

        {inzendingenSeries.length > 0 && <> <h4 className="statsKop" onClick={() => toggleCollapse("scoresSeries")}>
            Langste serie juiste antwoorden <i className="fas fa-sync-alt"/>
        </h4>
            <div id="scoresSeries" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>naam</td>
                        <td>aantal antwoorden</td>
                        <td>rondes</td>
                    </tr>
                    </thead>
                    <tbody>
                    {inzendingenSeries.map((serie) =>
                        <tr key={`${serie.SERIES[0].gebruiker}-${serie.SERIES[0].ronde}`}>
                            <td><Spelerlink speler={serie.SERIES[0].gebruiker} prijzen={false}/></td>
                            <td>{serie.LENGTH}</td>
                            <td><Rondelink ronde={serie.SERIES[0].ronde}/> t/m <Rondelink
                                ronde={serie.SERIES[serie.LENGTH-1].ronde}/>{serie.SERIES[serie.LENGTH-1].ronde=== huidigeRonde && '*'}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={3} style={{textAlign:'right'}}>
                            <br/>
                            * lopende serie&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }

        {rondesMeeste.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("rondesMeeste")}>Rondes met
            de meeste antwoorden</h4>
            <div id="rondesMeeste" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>ronde</td>
                        <td>aantal antwoorden</td>
                    </tr>
                    </thead>
                    <tbody>
                    {rondesMeeste.map((inzending) =>
                        <tr key={`${inzending.ronde}`}>
                            <td><Rondelink ronde={inzending.ronde}/></td>
                            <td>{inzending.count}x</td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <br/>
                <hr className="scorelijn"/>
            </div>
        </>
        }
        {rondesMinste.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("rondesMinste")}>Rondes met
            de minste antwoorden</h4>
            <div id="rondesMinste" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>ronde</td>
                        <td>aantal antwoorden</td>
                    </tr>
                    </thead>
                    <tbody>
                    {rondesMinste.map((inzending) =>
                        <tr key={`${inzending.ronde}`}>
                            <td>
                                <Rondelink ronde={inzending.ronde}/></td>
                            <td>{inzending.count}x</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <br/>
            </div>
        </>
        }
        {artiestenCorrects.length > 0 && <><h4 className="statsKop"
                                               onClick={() => toggleCollapse("artiestenCorrects")}>Best scorende
            artiesten</h4>
            <div id="artiestenCorrects" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>artiest
                        </td>
                        <td>aantal antwoorden</td>
                        <td>aantal rondes</td>
                        <td>antwoorden per ronde</td>
                    </tr>
                    </thead>
                    <tbody>
                    {artiestenCorrects.map((artiest) =>
                        <tr key={`${artiest.artiest}`}>
                            <td>
                                {artiest.artiest}
                            </td>
                            <td>{artiest.corrects}</td>
                            <td>
                                {artiest.count} ({artiest.rondes.map((r, i) =>
                                <Fragment key={r}>{i > 0 && ','}<Rondelink ronde={r}/></Fragment>
                            )})
                            </td>
                            <td>
                                {Math.round(artiest.corrects / artiest.count)}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <br/>
                <i style={{fontSize:'0.8em'}}>(deze lijst wordt een keer per dag geüpdated, rond half negen 's
                    avonds)</i>
            </div>
        </>
        }
        {jarenCorrects.length > 0 && <><h4 className="statsKop" onClick={() => toggleCollapse("jarenCorrects")}>Best
            scorende jaartallen</h4>
            <div id="jarenCorrects" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>jaartal</td>
                        <td>aantal antwoorden</td>
                        <td>aantal rondes</td>
                        <td>antwoorden per ronde</td>
                    </tr>
                    </thead>
                    <tbody>
                    {jarenCorrects.map((jaar) =>
                        <tr key={`${jaar.jaar}`}>
                            <td>{jaar.jaar}</td>
                            <td>{jaar.corrects}</td>
                            <td>{jaar.count}</td>
                            <td>{Math.round(jaar.corrects / jaar.count)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <br/>
                <i style={{fontSize:'0.8em'}}>(deze lijst wordt een keer per dag geüpdated, rond half negen 's
                    avonds)</i>
            </div>
        </>
        }
        {decenniaCorrects.length > 0 && <><h4 className="statsKop"
                                              onClick={() => toggleCollapse("decenniaCorrects")}>Best scorende
            decennia</h4>
            <div id="decenniaCorrects" className="statsLijst">
                <table className="scoretabel">
                    <thead>
                    <tr>
                        <td>decennium</td>
                        <td>aantal antwoorden</td>
                        <td>aantal rondes</td>
                        <td>antwoorden per ronde</td>
                    </tr>
                    </thead>
                    <tbody>
                    {decenniaCorrects.map((decennium) =>
                        <tr key={`${decennium.decennium}`}>
                            <td>{decennium.decennium}-{decennium.decennium + 9}</td>
                            <td>{decennium.corrects}</td>
                            <td>{decennium.count}</td>
                            <td>{Math.round(decennium.corrects / decennium.count)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <br/>
                <i style={{fontSize:'0.8em'}}>(deze lijst wordt een keer per dag geüpdated, rond half negen 's
                    avonds)</i>
            </div>
        </>
        }
        <hr className="scorelijn"/>
        <p className="oranje">
            Klik op een titel om de lijst te tonen of te verbergen.
        </p>
    </div>
}

export default Statistieken